import { error } from '../common/log'
import { maybe } from '../../../shared/helpers'

let allowIncomingTimeouts: boolean = true

let selectorsRetries: Record<string, number> = {}

export function retryRefreshElementsSets(
  selector: string,
  elem: Element | undefined,
  fnToRetry: () => void,
  ms = 100,
  maxRetires = 10
) {
  const elementFound = !!elem
  const selectorRetries = maybe(() => selectorsRetries[selector]) || 0
  const shouldRetry =
    !elementFound && allowIncomingTimeouts && selectorRetries < maxRetires
  if (shouldRetry) {
    allowIncomingTimeouts = false
    selectorsRetries[selector] =
      (maybe(() => selectorsRetries[selector]) || 0) + 1
    setTimeout(() => {
      try {
        fnToRetry()
      } catch (ex) {
        error(
          'failed to run setTimeout on refreshAllElementsSets',
          selector,
          ex
        )
      }
      allowIncomingTimeouts = true
    }, ms + 50 * selectorRetries)
  } else {
    // trackBadSelector(selector);
  }
}

export function removeSelectorFromElementsSetsRetriesWatch(selector: string) {
  if (!!selector && maybe(() => selectorsRetries[selector])) {
    delete selectorsRetries[selector]
  }
}

export function removeAllSelectorsFromElementsSetsRetriesWatch() {
  selectorsRetries = {}
}
