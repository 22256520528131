import { eventually, maybe } from './util'

// @ts-ignore
const get = () => edgetag('getUserId')

export function getEdgeTag() {
  const key = 'vsly_edgetag'
  eventually(() => {
    get() && localStorage.setItem(key, get())
    return true
  })
  return maybe(get) || localStorage.getItem(key)
}
