import { Command } from './command'
import { PageRedirectChange } from '../models'
import { MutateDeclarativeOptions } from '../index'
import { buildContextFromOpts, redirectTest } from '../utils'

export function newPageRedirectCommand(
  id: string,
  redirectOpts: PageRedirectChange,
  options?: MutateDeclarativeOptions
): Command {
  const _do = () => {
    const ctx = buildContextFromOpts(options!)
    redirectTest(ctx, redirectOpts.destUrl, redirectOpts.redirectAfter, redirectOpts.retainQueryParams === true, redirectOpts.stickinessMode)
  }

  const _undo = () => {
    return undefined
  }

  const _redo = () => {
    return false
  }

  return {
    id,
    kind: `pageRedirect`,
    isApplied: () => false,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
    setDebugId: (_: string) => (""),
  }
}
