export interface Item {
  quantity: number;
  id: string;
}

export interface AddToCartPayload {
  items: Item[];
}

export enum AnalyticEvent {
  SIGNUP = 'SIGNUP',

  ADD_TO_CART = 'ADD_TO_CART',
  CHANGE_QTY = 'CHANGE_QTY',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  PAGE_LOAD = 'PAGE_LOAD',
  CLICK = 'CLICK',

  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  TIME_SPENT = 'TIME_SPENT',
  USE_CASE = 'USE_CASE',

}
