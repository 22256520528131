import {
  Configuration,
  Conjunction,
  Timeout,
  TriggerType,
  UseCase,
} from '../../../shared/types'
import { applyOnPage } from '../common'
import { handleElementEvent, handleSelector } from './element'
import {
  awaitForAddEventListeners,
  awaitForRemoveEventListeners,
  maybe,
} from '../../../shared/helpers'
import { JitsuClient, reportError } from '../common/jitsu'
import { applyUseCase, shouldApplyNow } from './usecase_applier'
import { revertAllMutations } from '../dom_mutator'
import { awaitJsCondition, handleAsyncFunctionTrigger, handleJsCondition, handleJsEvent } from './custom'

type fn = () => void
type hash = number
let delayedTriggers: Array<fn | hash> = []

export interface CurrentExperienceHandler {
  matches: (experience: UseCase) => boolean;
  onSubscribeCall: (experience: UseCase) => void;
}

export const subscribeToTriggers = function(
  jitsu: JitsuClient,
  configuration: Configuration,
  currentExperienceHandler?: CurrentExperienceHandler
) {
  maybe(() =>
    configuration.experiments.forEach(experiment => {
      if (
        currentExperienceHandler &&
        currentExperienceHandler.matches(experiment)
      ) {
        currentExperienceHandler.onSubscribeCall(experiment)
      } else {
        handleUseCase(experiment, jitsu)
      }
    })
  )
}

export const pushDelayedTriggerSubscription = (item: fn | hash) => {
  delayedTriggers.push(item)
}

export const findDelayedTrigger = (item: hash | fn) => {
  return delayedTriggers.find(handler => handler === item)
}

export const unsubscribeFromTriggers = function() {
  delayedTriggers.forEach(handler => {
    try {
      typeof handler !== 'number' && handler()
    } catch (ex) {
      reportError()(`${ex}`)
    }
  })
  delayedTriggers = []
  revertAllMutations()
}

function handleUseCase(experiment: UseCase, jitsu: JitsuClient) {
  switch (maybe(() => experiment.trigger.type)) {
    case TriggerType.PageLoad:
      handlePageLoad(experiment, jitsu)
      break
    case TriggerType.Timeout:
      handleTimeout(experiment, jitsu)
      break
    case TriggerType.Inactivity:
      handleInactivity(experiment, jitsu)
      break
    case TriggerType.Selector:
      handleSelector(jitsu, experiment)
      break
    case TriggerType.ExitIntent:
      handleExitIntent(experiment, jitsu)
      break
    case TriggerType.JSCondition:
      handleJsCondition(experiment, jitsu)
      break
    case TriggerType.JSFunction:
      handleAsyncFunctionTrigger(experiment, jitsu)
      break
    case TriggerType.JSEvent:
      handleJsEvent(experiment, jitsu)
      break
    case TriggerType.Conjunction:
      handleConjunction(experiment, jitsu)
      break
    case TriggerType.ElementEvent:
      handleElementEvent(experiment, jitsu)
      break
    default:
      reportError()(`unknown trigger: ${JSON.stringify(experiment)}`)
  }
}

function handlePageLoad(experiment: UseCase, jitsu: JitsuClient) {
  applyOnPage(experiment, jitsu, () => applyUseCase(experiment))
}

function handleInactivity(experiment: UseCase, jitsu: JitsuClient) {
  const ms = (experiment.trigger as Timeout).timeoutMillis
  let inactivityTimer: any
  function resetTimer() {
    clearTimeout(inactivityTimer)
    inactivityTimer = setTimeout(() => {
      applyOnPage(experiment, jitsu, async () => {
        const applyNow = await shouldApplyNow(experiment)
        if (applyNow) {
          applyUseCase(experiment)
        }
        return applyNow
      })
    }, ms)
  }
  resetTimer()
  const events = ["mousemove", "keypress", "scroll", "touchstart", "touchmove"]
  pushDelayedTriggerSubscription(() => {
    clearTimeout(inactivityTimer)
    awaitForRemoveEventListeners(() => document)
      .then(() => events.forEach(e => document.removeEventListener(e, resetTimer)))
  })
  awaitForAddEventListeners(() => document, true).then(() => {
    events.forEach(e => document.addEventListener(e, resetTimer, { passive: true }))
  })
}

function handleTimeout(experiment: UseCase, jitsu: JitsuClient) {
  const ms = (experiment.trigger as Timeout).timeoutMillis
  let timer: any
  applyOnPage(experiment, jitsu, () => {
    timer = setTimeout(() => {
      applyUseCase(experiment)
    }, ms)
    return shouldApplyNow(experiment)
  })
  pushDelayedTriggerSubscription(() => {
    timer && clearTimeout(timer)
  })
}

function handleExitIntent(experiment: UseCase, jitsu: JitsuClient) {
  const mouseEvent = (e: MouseEvent) => {
    const b = maybe(() => !(e as any).toElement && !e.relatedTarget)
    if (b) {
      applyOnPage(experiment, jitsu, () => {
        const applied = applyUseCase(experiment)
        awaitForRemoveEventListeners(() => document).then(() => {
          document.removeEventListener('mouseout', mouseEvent)
        })
        return applied
      })
    }
  }
  awaitForAddEventListeners(() => document, true).then(() => {
    document.addEventListener('mouseout', mouseEvent, { passive: true })
  })
  pushDelayedTriggerSubscription(() => {
    awaitForRemoveEventListeners(() => document).then(() => {
      document.removeEventListener('mouseout', mouseEvent)
    })
  })
}

function handleConjunction(experiment: UseCase, jitsu: JitsuClient) {
  if (isConjunctionSupported(experiment)) {
    const conjunction = experiment.trigger as Conjunction
    awaitJsCondition(conjunction.jsCond)
      .then(() => {
        handleUseCase(
          {
            ...experiment,
            trigger: conjunction.condition,
          },
          jitsu
        )
      })
      .catch(ex => reportError()(`${(ex as any).toString()}`))
  }
}

function isConjunctionSupported(experiment: UseCase) {
  const trigger = experiment.trigger as Conjunction
  return [
    TriggerType.ExitIntent,
    TriggerType.Selector,
    TriggerType.Timeout,
  ].includes(trigger.condition.type)
}
