import { maybe } from '../../../shared/helpers'

export function getGaId(): string {
  return maybe(
    () =>
      (document.cookie
        .split(';')
        .map(x => x.split('='))
        .find(([k, _]) => k.trim() == '_ga') as any)[1],
    ''
  )
}
