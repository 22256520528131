import { maybe } from '../../../shared/util';
import { previewParamName } from '../../../shared/types';

const previewVariant = 'vsly_vid';
const lmiDebug = 'lmi_debug';


export function appendOptionsQueryParams(url: string) {
  const { previewKey, debugAllocator,previewVariantId } = getOptionalQueryParams();
  let ret = new URL(url);
  if (!!previewKey) {
    ret.searchParams.append(previewParamName, previewKey);
  }
  if (!!debugAllocator) {
    ret.searchParams.append(lmiDebug, 'true');
  }
  if (!!previewVariantId){
    ret.searchParams.append(previewVariant, previewVariantId);
  }

  return ret;
}

function getOptionalQueryParams() {
  [previewParamName,previewVariant].map(param => {
    const value = getQueryVariable(param);
    if (!!value) {
      sessionStorage.setItem(param, value);
    }
  })

  const previewKey = sessionStorage.getItem(previewParamName);
  const previewVariantId = sessionStorage.getItem(previewVariant);
  const debugAllocator = getQueryVariable(lmiDebug);
  return { previewKey, debugAllocator, previewVariantId };
}

function getQueryVariable(variable: string): string | undefined {
  return maybe(() => new URL(window.location.href).searchParams.get(variable) || undefined)
}
