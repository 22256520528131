import { createDebouncedFn } from './helpers'
import { TARGETING_EVENT_NAME } from './consts'

export enum TargetingEventType {
  CART_CHANGE = `CART_CHANGE`,
  LOYALTY_CHANGE = `LOYALTY_CHANGE`,
}

const debouncedTargetingChanged = createDebouncedFn((kind: TargetingEventType, payload: any) => {
  const event = getEvent(kind, payload)
  document.dispatchEvent(event)
  console.debug(`Targeting has changed:`, event)
}, 80)

export function fireTargetingEvent(kind: TargetingEventType, payload: any) {
  debouncedTargetingChanged(kind, payload)
}

function getEvent(kind: TargetingEventType, payload: any) {
  const event = new Event(TARGETING_EVENT_NAME)
  // @ts-ignore
  event.key = kind
  // @ts-ignore
  event.value = payload
  return event
}
