import { ElementEvent, Selector, UseCase } from '../../../shared/types'
import { applyOnPage } from '../common'
import { applyUseCase } from './usecase_applier'
import { maybe } from '../../../shared/util'
import { JitsuClient } from '../common/jitsu'

export const elementAppearsUseCases: Set<UseCase> = new Set()
export const elementEventCases: Set<UseCase> = new Set()
export const locatedElements: Set<string> = new Set()
export const locatedElementsWithEvents: Set<string> = new Set()

export let observer: MutationObserver

export function handleSelector(jitsu: any, experiment: UseCase) {
  initElementTriggerObserver(jitsu)
  elementAppearsUseCases.add(experiment)
  onDomMutation(jitsu)()
}

export function handleElementEvent(experiment: UseCase, jitsu: JitsuClient) {
  initElementTriggerObserver(jitsu)
  elementEventCases.add(experiment)
  onDomMutation(jitsu)()
}

export function initElementTriggerObserver(jitsu: any) {
  if (typeof observer === 'undefined') {
    observer = new MutationObserver(onDomMutation(jitsu))
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    })
  }
}

export function clearElementTriggerObserver() {
  if (typeof observer != 'undefined') {
    observer.disconnect()
  }
  elementAppearsUseCases.clear()
  elementEventCases.clear()
  locatedElements.clear()
  locatedElementsWithEvents.clear()
}

export function onDomMutation(jitsu: any) {
  return () => {
    handleObservation(elementAppearsUseCases, locatedElements, (located, useCase) => {
      applyOnPage(useCase, jitsu, () => {
        located.add(formatSelector((useCase.trigger as Selector).selector, useCase))
        return applyUseCase(useCase)
      })
    })

    handleObservation(elementEventCases, locatedElementsWithEvents, (located, useCase, element) => {
      const trigger = useCase.trigger as ElementEvent
      located.add(formatSelector(trigger.selector, useCase))
      element.addEventListener(trigger.name,
        () => applyOnPage(useCase, jitsu,
          () => applyUseCase(useCase)),
        { once: trigger.once === undefined ? false : trigger.once })
    })
  }
}

function handleObservation(useCases: Set<UseCase>, locatedSelectors: Set<string>, fn: (locatedSelectors: Set<string>, useCase: UseCase, element: HTMLElement) => void) {
  useCases.forEach(usecase => {
    maybe(() => {
      // @ts-ignore
      const selector = usecase.trigger.selector
      document.body.querySelectorAll(selector).forEach(element => {
        if (!locatedSelectors.has(formatSelector(selector, usecase))) {
          fn(locatedSelectors, usecase, element)
        }
      })
    })
  })
}

function formatSelector(selector: string, useCase: UseCase) {
  return `${selector}-${useCase.name}`
}
