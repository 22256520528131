import { JitsuClient } from '../sdk/core/common/jitsu'
import { ENV } from './types'
import { shouldDeferResources } from './helpers'

export function awaitCondition(
  condition: () => boolean,
  timeout = 500,
  retries = 4,
  shouldCancel?: () => boolean
) {
  return new Promise((resolve, reject) => {
    if (condition()) {
      resolve(0)
      return
    }
    if (retries === 0) {
      reject(`condition rejected ${maybe(() => condition.toString())}`)
      return
    }
    setTimeout(() => {
      if (shouldCancel && shouldCancel()) {
        reject(`condition rejected ${maybe(() => condition.toString())}`)
      }
      awaitCondition(condition, timeout, retries - 1)
        .then(resolve)
        .catch(reject)
    }, timeout)
  })
}

export function when(condition: () => any, timeout = 500) {
  function inner(resolve: any) {
    try {
      const res = condition()
      if (res) {
        resolve(res)
      } else {
        setTimeout(() => inner(resolve), timeout)
      }
    } catch (ex) {
      console.error(`vsly-when`, ex)
      setTimeout(() => inner(resolve), timeout)
    }
  }

  return new Promise(resolve => {
    inner(resolve)
  })
}

export function toStr(a: any) {
  return maybe(() => a.toString(), '')
}

export function errToStr(e: any) {
  return toStr(e) + ' stack: ' + maybe(() => (e as Error).stack, '')
}
export function maybe<T>(f: () => T, def: T | undefined = undefined) {
  try {
    return f()
  } catch {
    return def
  }
}

export async function awaitJitsu(): Promise<JitsuClient | undefined> {
  await awaitCondition(
    () => {
      return !!maybe(() => window.loomi.jitsu)
    },
    shouldDeferResources() ? 1000 : 500,
    120
  )
  return maybe(() => window.loomi.jitsu)
}

export function reportError(): any {
  const promise = awaitJitsu()
  return function(err: string) {
    return promise.then(() => {
      maybe(() => window.loomi.reportSdkErr!(err))
    })
  }
}

export const URL_CHANGED_EVENT = 'vslyUrlChanged'
export const ALLOCATION_DONE = 'vslyAllocationDone'
export const getCookie = (name: string) => {
  if (!name) {
    return null
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
          encodeURIComponent(name).replace(/[\-\.\+\*]/g, '\\$&') +
          '\\s*\\=\\s*([^;]*).*$)|^.*$',
        ),
        '$1',
      ),
    ) || null
  )
}

export function getClientId(): string | undefined {
  const getST = () => document.querySelector('script[id="__st"]')
  eventually(() => {
    const cid: string = new Function(`${(getST())!.textContent};return __st.cid.toString()`)()
    cid && window.localStorage.setItem('vsly__st', cid)
    return true
  })
  // @ts-ignore
  const maybeId = maybe(() => window.__st.cid.toString()) as string
  if (!!maybeId) {
    return maybeId
  }
  return window.localStorage.getItem(`vsly__st`) as string
}

export function eventually(f: () => boolean, attempts = 5, timeout = 500) {
  if (attempts >= 0 && !maybe(f)) {
    setTimeout(() => {
      if (!maybe(f)) {
        eventually(f, attempts - 1)
      }
    }, timeout)
  }
}

export function initLoomiCtx() {
  window.loomi_ctx = window.loomi_ctx || {}
}

export function setupLoomiCtx(env: ENV, storeAlias: string, key?: string) {
  initLoomiCtx()
  window.loomi_ctx.env = window.loomi_ctx.env || env
  window.loomi_ctx.storeAlias = window.loomi_ctx.storeAlias || storeAlias
  key && (window.loomi_ctx.jitsuKey = window.loomi_ctx.jitsuKey || key)
}

export function initTagIdsSet(tagIds: any[]) {
  window.loomi_ctx.tagIds = (window.loomi_ctx.tagIds || new Set<string>())
  tagIds.forEach(t => window.loomi_ctx.tagIds!.add(t))
  window.loomi_ctx.gtags = (window.loomi_ctx.gtags || [])
}

export const getGoogleCDN = (env: ENV | undefined = window.loomi_ctx.env): 'live.visually-io.com' | `sdk.loomi-stg.xyz` => {
  if (env === ENV.STAGE) {
    return `sdk.loomi-stg.xyz`
  }
  return 'live.visually-io.com'
}

export function sanitizeGaEvent(event: string): string {
  return event.replace(/[^a-z_A-Z0-9]/gi, '_')
}
