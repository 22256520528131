// DON'T CHANGE, is being used by the backdrop container in svc-apps-catalog
import { debug } from './log'
import { awaitForAddEventListeners, maybe } from '../../../shared/helpers'

const BACKDROP_ID = 'vsly-backdrop-container'
// DON'T CHANGE, is being used by the crosshair in svc-preview-proxy
const CROSSHAIR_ID = '__loomi_crosshair_container'

const ELEMENT_IDS_TO_IGNORE_TRAP = [BACKDROP_ID, CROSSHAIR_ID]

export function stopBubblingHandler(ev: Event) {
  let target: HTMLElement | undefined | null = maybe(
    () => ev.target
  ) as HTMLElement
  let isClickOnBackdrop = false
  while (!!target) {
    if (
      ELEMENT_IDS_TO_IGNORE_TRAP.includes(
        maybe(() => (target as HTMLElement).id)!
      )
    ) {
      isClickOnBackdrop = true
      break
    }
    target = maybe(() => (target as HTMLElement).parentElement)
  }

  if (isClickOnBackdrop) {
    debug(`vsly`, `about to stop propagation of event`, ev)
    maybe(() => ev.stopImmediatePropagation())
    maybe(() => ev.stopPropagation())
  }
}

export function removeFocusTrapFromBackdrop() {
  if (maybe(() => window.visually.flags[`op-focus-trap-removal`] === true)) {
    awaitForAddEventListeners(() => document, true).then(() => {
      document.addEventListener('focusin', stopBubblingHandler, { capture: true, passive: true })
      document.addEventListener('mousedown', stopBubblingHandler, { capture: true, passive: true })
      document.addEventListener('touchstart', stopBubblingHandler, { capture: true, passive: true })
      document.addEventListener('touchend', stopBubblingHandler, { capture: true, passive: true })
    })
  }
}
