import { _IS_DEBUG, FontChange, LOOMI_ATTR } from '../models'
import { Command } from './command'
import { maybe } from '../../../../shared/helpers'

export function newAppendFontCommand(
  id: string,
  fontChange: FontChange,
  debugId?: string
): Command {
  debugId = debugId || id
  let isApplied = false
  const linkElement = document.createElement(`link`)
  const href = formatGoogleFontsLink(fontChange.family, fontChange.weights)
  linkElement.setAttribute('href', href)
  linkElement.setAttribute('rel', 'stylesheet')

  const _do = () => {
    if (isApplied) return
    _IS_DEBUG && linkElement.setAttribute(LOOMI_ATTR, debugId!)
    document.head.appendChild(linkElement)
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    linkElement.remove()
    isApplied = false
    return undefined
  }

  return {
    id,
    isApplied: () => isApplied,
    kind: `appendFont`,
    do: _do,
    undo: _undo,
    redoIfNeeded: () => false,
  }
}

function formatGoogleFontsLink(name: string, weights: number[]): string {
  if (name.toLowerCase().includes(`jetbrains`)) name = `JetBrains Mono`
  const encodedName = encodeURIComponent(name)
  const encodedWeights = maybe(() => weights.join(';'))
  return `https://fonts.googleapis.com/css2?family=${encodedName}:wght@${encodedWeights}&display=block`
}
