import { Command, redoWhenElementIsDetached } from './command'
import { _IS_DEBUG, LOOMI_ATTR } from '../models'

export function newAppendCssCommand(
  id: string,
  selector: string,
  css: string,
  debugId?: string
): Command {
  let _debugId = debugId || id
  let isApplied = false
  let origin = document.querySelector(selector) as HTMLElement
  const styleElement = document.createElement(`style`)

  const _do = () => {
    if (isApplied) return
    styleElement.innerHTML = css
    _IS_DEBUG && styleElement.setAttribute(LOOMI_ATTR, _debugId)
    document.head.appendChild(styleElement)
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    styleElement.remove()
    isApplied = false
    return undefined
  }

  const _redo = () => {
    const resp = redoWhenElementIsDetached(_do, _undo, selector, origin)
    if (resp.element) origin = resp.element
    return resp.isDetached
  }

  return {
    id,
    kind: `appendCss`,
    isApplied: () => isApplied,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
    setDebugId: (debugId: string) => (_debugId = debugId),
  }
}
