import { Command } from './command'
import { AutomationStep } from '../models'
import { debug, error } from '../../common/log'
import { awaitCondition, maybe } from '../../../../shared/helpers'

export function newAutomationCommand(steps: AutomationStep[]): Command {
  let isApplied = false

  const _do = () => {
    if (isApplied) return
    isApplied = true
    try {
      steps
        .sort((a, b) => a.order - b.order)
        .map(step => {
          switch (step.kind) {
            case `click`:
              return () => click(step)
            case `type`:
              return () => type(step)
            case `scroll`:
              return () => scrollIntoView(step)
            case `wait`:
              return () => wait(step)
            default:
              return () => Promise.resolve()
          }
        })
        .reduce((prev, next) => prev.then(_ => next()), Promise.resolve())
        .then()
    } catch (ex) {
      error('failed to run automation with exception:', ex)
    }
  }

  return {
    id: `automation-${new Date().getDate()}`,
    kind: `automation`,
    isApplied: () => isApplied,
    do: _do,
    undo: () => undefined,
    redoIfNeeded: () => false,
  }
}

export function forceRunAutomation(steps: AutomationStep[]): void {
  const cmd = newAutomationCommand(steps)
  cmd.do()
}

async function click(step: AutomationStep): Promise<void> {
  try {
    debug(`fake-click`, `about to click on:`, step.selector)
    const element = await awaitForSelector(step.selector!)
    element && element.click()
    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function type(step: AutomationStep): Promise<void> {
  try {
    debug(
      `fake-click`,
      `about to type on:`,
      step.selector,
      `with text: `,
      step.data
    )
    const element = await awaitForSelector(step.selector!)
    // @ts-ignore
    const nativeInputValueSetter = maybe(
      () =>
        // @ts-ignore
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        ).set
    )
    maybe(() => nativeInputValueSetter!.call(element, step.data || ``))
    const ev2 = new Event('input', { bubbles: true })
    element.dispatchEvent(ev2)

    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function scrollIntoView(step: AutomationStep): Promise<void> {
  try {
    debug(`fake-click`, `about to scroll to:`, step.selector)
    const element = await awaitForSelector(step.selector!)
    element.scrollIntoView(true)
    return Promise.resolve()
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function wait(step: AutomationStep): Promise<void> {
  try {
    debug(`fake-click`, `about to wait for: ${step.data}ms`)
    return new Promise(resolve => {
      setTimeout(() => resolve(), maybe(() => step.data) || 1000)
    })
  } catch (ex) {
    return Promise.reject(ex)
  }
}

async function awaitForSelector(selector: string): Promise<HTMLElement> {
  try {
    await awaitCondition(
      () => !!maybe(() => document.querySelector(selector)),
      250,
      100
    )
    return Promise.resolve(document.querySelector(selector) as HTMLElement)
  } catch (ex) {
    return Promise.reject(ex)
  }
}
