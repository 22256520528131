import { Command } from './command'
import { _IS_DEBUG, _replaceOuterHtml, LOOMI_ATTR } from '../models'
import { hasEnoughHeightStrategy } from '../strategy_has_enough_height'

export function newReplaceHtmlCommand(
  id: string,
  selector: string,
  element: Element,
  htmlReplacement: string,
  debugId?: string
): Command {
  let _debugId = debugId || id
  let isApplied = false
  const originalValue = element.outerHTML

  const _do = () => {
    if (isApplied) return
    _IS_DEBUG && element.setAttribute(LOOMI_ATTR, _debugId)
    if (element.outerHTML === htmlReplacement) return
    element = _replaceOuterHtml(element, htmlReplacement)
    hasEnoughHeightStrategy(element as HTMLElement, selector)
    _IS_DEBUG && element.setAttribute(LOOMI_ATTR, _debugId)
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    if (element.outerHTML === originalValue) return
    element = _replaceOuterHtml(element, originalValue)
    isApplied = false
    return undefined
  }

  return {
    id,
    isApplied: () => isApplied,
    kind: `replace`,
    do: _do,
    undo: _undo,
    redoIfNeeded: () => false,
    setDebugId: (debugId: string) => (_debugId = debugId),
  }
}
