import { maybe } from '../../shared/util'

export function getCurrentProductDetails() {
  const ret = {} as { productId: string; variantId: string }
  const ctx = window.loomi_ctx || {}
  if (ctx.productId) {
    ret.productId = ctx.productId
  }
  const vid = takeVariantIdFromSearchParams() || ctx.variantId
  if (vid) {
    ret.variantId = vid
  }
  return ret
}

export function takeVariantIdFromSearchParams() {
  return maybe(() => {
    const params = new URL(location.href).searchParams
    const name = "variant"
    return params.has(name) && cleanVariantId(params.get(name)!)
  })
}

export function cleanVariantId(variantId: string | number) {
  const vid = Number(variantId)
  const ret = isNaN(vid)
    ? Number(`${variantId}`.replace(/[^\d.]+/g, '')) || undefined// NaN is false :)
    : vid
  if (typeof ret === 'undefined' || ret === 0) {
    return undefined
  }
  return `${ret}`
}
