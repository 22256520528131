import { AllMutationKind } from '../models'
import { maybe } from '../../../../shared/helpers'

export interface Command {
  id: string;
  kind: AllMutationKind;

  /**
   * Will return if the command has been applied
   */
  isApplied: () => boolean;

  /**
   * Will apply the command
   */
  do: () => void;

  /**
   * Will undo the command
   */
  undo: () => any;

  /**
   * Will redo the command, typically with a more "performant" way, only if the internal state is stale.
   * used by commands that need to rerender/refresh their state based on external dependencies
   * such as: the "depended on" element has been changed or removed, etc...
   *
   * Returns true, if the command redo was applied.
   */
  redoIfNeeded: () => boolean;

  setDebugId?: (debugId: string) => void;
}

export interface RedoWhenDetached {
  isDetached: boolean;
  element?: HTMLElement;
}

export function redoWhenElementIsDetached(
  _do: () => void,
  _undo: () => any,
  selector: string,
  element: HTMLElement | null
): RedoWhenDetached {
  if (!document.body.contains(element)) {
    const newElement = document.querySelector(selector) as HTMLElement
    _undo()
    _do()
    return { element: newElement, isDetached: true }
  }
  return { isDetached: false }
}

export function findVisible(selector: string) {
  const flagEnabled = maybe(() => window.visually.flags['visibility-redo'])
  if (!flagEnabled) {
    return document.querySelector(selector) as HTMLElement
  }

  const nodes = document.querySelectorAll(selector)
  for (let i = 0; i < nodes.length; i++) {
    const el = nodes.item(i)
    if (isVisible(el)) {
      return el as HTMLElement
    }
  }
  return maybe(() => nodes[0]) as HTMLElement
}

export function isVisible(el: Element | HTMLElement | null): boolean {
  try {
    if (!maybe(() => window.visually.flags['visibility-redo'])) {
      return true
    }
    if (!el) {
      return false
    }
    const boundingClientRect = el.getBoundingClientRect()
    const computedStyles = getComputedStyle(el)
    return !maybe(
      () =>
        computedStyles.display == 'none' ||
        computedStyles.opacity == '0' ||
        computedStyles.visibility == 'hidden' ||
        (boundingClientRect.width <= 5 && boundingClientRect.height <= 5) ||
        (el as HTMLElement).style.display == 'none' ||
        (el as HTMLElement).style.opacity == '0' ||
        (el as HTMLElement).style.visibility == 'hidden'
    )
    // add position check
  } catch (e) {
    return true
  }
}
