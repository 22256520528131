
const DB_NAME = 'VISUALLY_IO'
const STORE_NAME = 'VISUALLY_IO'
let db: IDBDatabase | null = null

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#version_changes_while_a_web_app_is_open_in_another_tab
    // PRIO TO VERSION UPGRADE HANDLE THIS
    const request = indexedDB.open(DB_NAME, 1)
    request.onerror = (event) => {
      reject('IDB e:o' + (event.target as IDBOpenDBRequest).error)
    }
    request.onsuccess = (event) => {
      db = (event.target as IDBOpenDBRequest).result
      resolve(db)
    }
    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const objectStore = db.createObjectStore(STORE_NAME, { keyPath: 'id' })
      objectStore.createIndex('id', 'id', { unique: true })
    }
  })
}

export async function getIndexDB(): Promise<IDBDatabase> {
  if (!db) {
    await openDatabase()
  }
  return db as IDBDatabase
}

export async function dbWrite(data: any): Promise<string> {
  const database = await getIndexDB()
  return new Promise((resolve, reject) => {
    const request = database
      .transaction([STORE_NAME], 'readwrite')
      .objectStore(STORE_NAME)
      .put(data)
    request.onerror = (event) => {
      reject('IDB e:w' + (event.target as IDBRequest).error)
    }
    request.onsuccess = () => {
      resolve('')
    }
  })
}

export async function dbRead(id: string): Promise<any> {
  const database = await getIndexDB()
  return new Promise((resolve, reject) => {
    const request = database
      .transaction([STORE_NAME], 'readonly')
      .objectStore(STORE_NAME)
      .get(id)

    request.onerror = (event) => {
      reject('IDB e:r' + (event.target as IDBRequest).error)
    }
    request.onsuccess = (event) => {
      resolve((event.target as IDBRequest).result)
    }
  })
}
