import { Command, redoWhenElementIsDetached } from './command'
import {
  _IS_DEBUG,
  _replaceOuterHtml,
  HtmlMutationKind,
  LOOMI_ATTR,
} from '../models'
import { hasEnoughHeightStrategy } from '../strategy_has_enough_height'
import { maybe } from '../../../../shared/helpers'

export function newAppendHtml(
  id: string,
  selector: string,
  html: string,
  kind: HtmlMutationKind,
  debugId?: string
): Command {
  let _debugId = debugId || id
  let isApplied = false
  let origin = document.querySelector(selector) as HTMLElement
  let appendedElement = document.createElement(`div`) as HTMLElement

  function appendBefore() {
    origin = document.querySelector(selector) as HTMLElement
    // @ts-ignore
    maybe(() => origin.parentNode.insertBefore(appendedElement, origin))
    hasEnoughHeightStrategy(origin, selector)
    appendedElement = _replaceOuterHtml(appendedElement, html) as HTMLElement
    _IS_DEBUG && appendedElement.setAttribute(LOOMI_ATTR, _debugId)
    isApplied = true
  }

  function appendAfter() {
    if (isApplied) return
    origin = document.querySelector(selector) as HTMLElement
    if (origin.nextSibling) {
      maybe(() =>
        // @ts-ignore
        origin.parentNode.insertBefore(appendedElement, origin.nextSibling)
      )
      hasEnoughHeightStrategy(origin as HTMLElement, selector)
    } else {
      // @ts-ignore
      maybe(() => origin.parentNode.appendChild(appendedElement))
      hasEnoughHeightStrategy(origin as HTMLElement, selector)
    }
    appendedElement = _replaceOuterHtml(appendedElement, html) as HTMLElement
    _IS_DEBUG && appendedElement.setAttribute(LOOMI_ATTR, _debugId)
    isApplied = true
  }

  const _do = () => {
    if (isApplied) return
    if (kind === `appendBefore`) appendBefore()
    if (kind === `appendAfter`) appendAfter()
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    appendedElement.remove()
    isApplied = false
    return undefined
  }

  const _redo = () => {
    const resp = redoWhenElementIsDetached(_do, _undo, selector, origin)
    if (resp.element) origin = resp.element
    return resp.isDetached
  }

  return {
    id,
    isApplied: () => isApplied,
    kind,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
    setDebugId: (debugId: string) => (_debugId = debugId),
  }
}
