import { Command } from './command'
import { awaitCondition, maybe } from '../../../../shared/helpers'

export function newVisualEditCommand(id: string, data: VisualEdit[]): Command {
  let isApplied = false

  let watchlist: Record<string, VisualEditWatch> = {}

  const _do = () => {
    if (isApplied) return
    isApplied = true
    data.forEach(item => {
      awaitCondition(
        () => !!document.querySelector(item.selector),
        250,
        300
      ).then(() => {
        const elem = document.querySelector(item.selector) as HTMLElement
        handleVisualEdit(elem, item)
      })
    })
  }

  const handleVisualEdit = (elem: HTMLElement, ve: VisualEdit, updateWatchList = true) => {
    const reverts: RevertChange[] = ve.subChanges.map(change => {
      switch (change.kind) {
        case 'text':
          return setText(elem, change, ve.version)
        case 'style':
          return setStyle(elem, change)
        case 'image':
          return setImage(elem, change)
        case 'video':
          return setVideo(elem, change)
        case 'attr':
          return setAttr(elem, change)
      }
    })
    if (updateWatchList) {
      watchlist[ve.selector] = { ve, elem, reverts }
    }
  }

  const getVisual = (elem: HTMLElement, ve: VisualEditSubChange, version?: number) => {
    switch (ve.kind) {
      case 'text':
        return getText(elem, ve, version)
      case 'attr':
        return getAttr(elem, ve)
      case 'style':
        return getStyle(elem, ve)
      case 'image':
        return getMedia(elem)
      case 'video':
        return getMedia(elem)
    }
  }

  const _undo = () => {
    if (!isApplied) return
    isApplied = false
    Object.values(watchlist).forEach(value =>
      value.reverts.forEach(revert => revert())
    )
    watchlist = {}
    return undefined
  }

  function revertAndRebuild(value: VisualEditWatch) {
    value.reverts.forEach(revert => revert())
    const newElem = document.querySelector(value.ve.selector) as HTMLElement
    newElem && handleVisualEdit(newElem, value.ve)
    return true
  }

  function revertAndApply(value: VisualEditWatch, changesMade: boolean) {
    value.ve.subChanges.forEach((subChange, idx) => {
      if (subChange.value !== getVisual(value.elem, subChange, value.ve.version)) {
        maybe(() => value.reverts[idx]())
        handleVisualEdit(value.elem, {
          selector: value.ve.selector,
          subChanges: [subChange],
        }, false)
        changesMade = true
      }
    })
    return changesMade
  }

  const _redo = () => {
    if (!isApplied) return false
    let changesMade = false
    Object.values(watchlist).forEach((value) => {
      if (!document.body.contains(value.elem)) {
        changesMade = revertAndRebuild(value)
      } else {
        changesMade = revertAndApply(value, changesMade)
      }
    })

    return changesMade
  }

  return {
    id,
    kind: `visualEdit`,
    isApplied: () => isApplied,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
  }
}

interface VisualEditWatch {
  ve: VisualEdit;
  elem: HTMLElement;
  reverts: RevertChange[];
}

export interface VisualEdit {
  selector: string;
  subChanges: VisualEditSubChange[];
  version?: number
}

export interface VisualEditSubChange extends Pair {
  kind: `text` | `attr` | `style` | `image` | `video`;
}

export interface Pair {
  key?: string;
  value: string;

  extra?: number | string;
}

type RevertChange = () => void

const setAttr = (elem: HTMLElement, pair: Pair): RevertChange => {
  if (!elem || !elem.setAttribute || !elem.getAttribute) return () => {}
  const origAttr = elem.getAttribute(pair.key!)
  elem.setAttribute(pair.key!, pair.value)
  return () =>
    origAttr
      ? elem.setAttribute(pair.key!, origAttr)
      : elem.removeAttribute(pair.key!)
}

const getAttr = (elem: HTMLElement, pair: Pair): string | null => {
  return elem.getAttribute(pair.key!)
}

function shouldApplyOnTextNode(elem: HTMLElement, pair: Pair, version?: number): boolean {
  const childTextNodeIdx = pair.extra as number
  if (!!version && version >= 3) {
    return typeof childTextNodeIdx !== 'undefined' && !!maybe(() => elem.childNodes[childTextNodeIdx])
  }
  // For backwards compatibility, this is a bug that was fixed in version 2,
  // but we can phase it out since its being used by 100's of published experiments
  return !!childTextNodeIdx && !!maybe(() => elem.childNodes[childTextNodeIdx])
}

const setText = (elem: HTMLElement, pair: Pair, version?: number): RevertChange => {
  if (!elem) return () => {}
  const childTextNodeIdx = pair.extra as number
  if (shouldApplyOnTextNode(elem, pair, version)) {
    const origValue = elem.childNodes[childTextNodeIdx].textContent
    elem.childNodes[childTextNodeIdx].textContent = pair.value
    return () => (elem.childNodes[childTextNodeIdx].textContent = origValue)
  } else {
    const origValue = elem.textContent
    elem.textContent = pair.value
    return () => (elem.textContent = origValue)
  }
}

const getText = (elem: HTMLElement, pair: Pair, version?: number): string | null => {
  const childTextNodeIdx = pair.extra as number
  if (shouldApplyOnTextNode(elem, pair, version)) {
    return elem.childNodes[childTextNodeIdx].textContent
  }
  return elem.textContent
}

const setStyle = (elem: HTMLElement, pair: Pair): RevertChange => {
  if (!elem) return () => {}
  // @ts-ignore
  const origStyle = maybe(() => elem.style[pair.key], null)
  elem.style.setProperty(pair.key!, pair.value, `important`)
  return () => elem.style.setProperty(pair.key!, origStyle)
}

const getStyle = (elem: HTMLElement, pair: Pair): string => {
  // @ts-ignore
  return maybe(() => elem.style[pair.key], null)
}

const setImage = (elem: HTMLElement, pair: Pair): RevertChange => {
  if (!elem || !elem.removeAttribute || !elem.getAttribute) return () => {}
  const origSrcset = elem.getAttribute(`srcset`) || ``
  elem.removeAttribute(`srcset`)
  // @ts-ignore
  const origSrc = maybe(() => elem.src, undefined)
  // @ts-ignore
  elem.src = pair.value

  return () => {
    elem.setAttribute(`srcset`, origSrcset)
    // @ts-ignore
    elem.src = origSrc
  }
}

const setVideo = (elem: HTMLElement, pair: Pair): RevertChange => {
  if (!elem || !elem.setAttribute) return () => {}
  const origHtml = elem.innerHTML
  elem.innerHTML = ``
  const origSrc = elem.getAttribute(`src`) || ``
  elem.setAttribute(`src`, pair.value)

  return () => {
    elem.innerHTML = origHtml
    elem.setAttribute(`src`, origSrc)
  }
}

const getMedia = (elem: HTMLElement): string => {
  return (elem as HTMLImageElement).src
}
