import { maybe } from '../../../shared/util'
import { CartBase } from '../../../shared/shopifyTypes'
import { getCartSubscriptions } from '../../../shared/cart_utils'
import { UseCase } from '../../../shared/types'

// used by client side targeting
export function CartTotal(currency: string, autoApply?: boolean): number {
  // @ts-ignore
  const cart = this.cart as CartBase | undefined
  if (!cart) {
    return 0
  }
  if (!maybe(() => window.visually.flags['currency-aware-client-targeting'])) {
    return 0
  }
  return <number>maybe(() => {
    if (cart.currency === currency) {
      return cart.total_price! / 100
    } else if (autoApply) {
      return (
        (window.loomi_ctx.Currency.convert(
          cart.total_price,
          cart.currency,
          currency
        ) || 0) / 100
      )
    }
    return 0
  }, 0)
}

export function CartHasSubs(value: string, op: string): boolean {
  function toLower(x: string) {
    return x.toLowerCase()
  }
  const ops = {
    '==': (x: string) => toLower(x) == toLower(value),
    '!=': (x: string) => toLower(x) != toLower(value),
    contains: (x: string) => toLower(x).includes(toLower(value)),
    matches: (x: string) => new RegExp(value).test(x),
  } as any
  // @ts-ignore
  const subs = this.CartSubs() as Array<string>
  return !!subs.find((x: string) => ops[op](x))
}

export function CartSubs(): Array<string> {
  // @ts-ignore
  const cart = this.cart
  return getCartSubscriptions(cart)
}

export function experiencesHasEnhancedCartTargetingFormula(useCases: UseCase[]): boolean {
  if (!useCases) return false
  return useCases.findIndex(exp => {
    const csf = maybe(() => exp.clientTargetingFormula!.toString(), "") || ""
    return csf.includes("CartItemHasTag") || csf.includes("CartItemHasCollection") || csf.includes("AllCartItemsHasTag")
  }) > -1
}

export function CartHasItem(value: string): boolean {
  // @ts-ignore
  const cart = this.cart as CartBase
  return !!maybe(() => cart.items.find(i => i.handle === value))
}

export function CartItemHasTag(value: string): boolean {
  // @ts-ignore
  const cart = this.cart as CartBase
  return !!maybe(() => cart.items.find(i => {
    if (i.tags) {
      return i.tags.includes(value)
    }
    return false
  }))
}

export function AllCartItemsHasTag(value: string): boolean {
  // @ts-ignore
  const cart = this.cart as CartBase
  if (cart.items.length <= 0) {
    return false
  }
  const itemsWithTags = maybe(() => cart.items.filter(i => {
    if (i.tags) {
      return i.tags.includes(value)
    }
    return false
  }))

  return maybe(() => itemsWithTags!.length === cart.items.length, false) as boolean
}

export function CartItemHasCollection(value: string): boolean {
  // @ts-ignore
  const cart = this.cart as CartBase
  return !!maybe(() => cart.items.find(i => {
    if (i.collections) {
      return i.collections.includes(value)
    }
    return false
  }))
}
