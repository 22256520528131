import { maybe } from '../../../shared/util'

export function isInEditor() {
  // @ts-ignore
  return !!maybe(() => window.vsly_fbs)
}

function shouldLog() {
  return window.location.search.includes('lmi_debug') || isInEditor()
}

export function debug(...args: Array<any>) {
  if (shouldLog()) {
    // eslint-disable-next-line no-console
    console.debug(`loomi-editor`, ...args)
  }
}

export function warn(...args: Array<any>) {
  if (shouldLog()) {
    console.warn('loomi-editor', ...args)
  }
}

export function error(...args: Array<any>) {
  if (shouldLog()) {
    console.error('loomi-editor', ...args)
  }
}
