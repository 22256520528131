import { maybe } from '../../../shared/util'

export function IsLoyaltyCustomer(): boolean {
  // @ts-ignore
  return maybe(() => window.loomi_ctx.loyalty.isMember, false) === true
}

export function LoyaltyPointsBalance(): number {
  // @ts-ignore
  return maybe(() => window.loomi_ctx.loyalty.pointsBalance, 0)
}

export function LoyaltyPointsEarned(): number {
  // @ts-ignore
  return maybe(() => window.loomi_ctx.loyalty.earnedPoints, 0)
}
