import {
  generateId,
  getCookieDomain,
  setCookie,
  maybe,
} from '../../../shared/helpers'
import { getCookie } from '../../../shared/util'

const cookieName = '__eventn_id'

export function getTrackingId(): { id: string; firstSession: boolean, firstSeen: number } {
  const { id, firstSeen } = getAnonymousId(cookieName, getCookieDomain())
  const firstSession = new Date().getTime() / 1000 - firstSeen <= 7200
  return { id, firstSession, firstSeen }
}

export function overrideTrackingId(uid: string, firstSeen: number) {
  const value = cookieValue(uid, firstSeen)
  localStorage.setItem(cookieName, value)
  setCookie(
    cookieName,
    value,
    Infinity,
    getCookieDomain(),
    document.location.protocol !== 'http:'
  )
}

export function getAnonymousId(cookieName: string, cookieDomain: string) {
  const { id: cId, firstSeen: cDate } = getCookieId(cookieName, cookieDomain)
  const { id: lId, firstSeen: lDate } = getLocalStorageId(cookieName, cId)
  if (cDate <= lDate) {
    overrideLocalStorage(lId, cId, cookieName, lDate)
    return { id: cId, firstSeen: cDate }
  } else {
    overrideCookie(lId, cId, cookieName, cDate, cookieDomain)
    return { id: lId, firstSeen: lDate }
  }
}

export function getCookieId(cookieName: string, cookieDomain: string) {
  const idCookie = getCookie(cookieName)
  const { firstSeen, id } = parseCookie(idCookie)
  if (idCookie) {
    return { id, firstSeen: firstSeen || 0 }
  }
  const { newId, newEpoch, value } = newCookieValue()

  setCookie(
    cookieName,
    value,
    Infinity,
    cookieDomain,
    document.location.protocol !== 'http:'
  )
  return { id: newId, firstSeen: newEpoch }
}

export function getLocalStorageId(cookieName: string, cookieId: string) {
  const idCookie = window.localStorage.getItem(cookieName)
  const { id, firstSeen } = parseCookie(idCookie)
  if (idCookie) {
    return { id, firstSeen: firstSeen || 1 }
  }
  const { newId, newEpoch, value } = newCookieValue(cookieId)
  window.localStorage.setItem(cookieName, value)
  return { id: newId, firstSeen: newEpoch }
}

function parseCookie(idCookie: string | null) {
  const firstSeen = maybe(
    () => parseInt((idCookie as string).split('.')[1]),
    null
  )
  const id = maybe(() => (idCookie as string).split('.')[0]) as string
  return { firstSeen, id }
}

export function newCookieValue(cookieId?: string) {
  const newId = cookieId || generateId()
  const newEpoch = currSecond()
  const value = cookieValue(newId, newEpoch)
  return { newId, newEpoch, value }
}

function cookieValue(newId: string, newEpoch: number) {
  return `${newId}.${newEpoch}`
}

function overrideLocalStorage(
  localStorageID: string,
  cookieId: string,
  cookieName: string,
  localStorageFirstSeen: number
) {
  if (localStorageID != cookieId) {
    localStorage.setItem(
      cookieName,
      cookieValue(cookieId, localStorageFirstSeen)
    )
  }
}

function overrideCookie(
  localStorageID: string,
  cookieId: string,
  cookieName: string,
  cookieFirstSeen: number,
  cookieDomain: string
) {
  if (localStorageID != cookieId) {
    setCookie(
      cookieName,
      cookieValue(localStorageID, cookieFirstSeen),
      Infinity,
      cookieDomain,
      document.location.protocol !== 'http:'
    )
  }
}

function currSecond() {
  return Math.trunc(new Date().getTime() / 1000)
}
