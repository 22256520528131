import { UseCase } from '../../../shared/types'
import { JitsuClient } from './jitsu'
import { AnalyticEvent } from './events'
import { maybe } from '../../../shared/util'
import { hasClientSideFormula } from '../triggers/usecase_applier'
import { persistClientSideAllocation } from '../init'

export async function applyOnPage(
  experiment: UseCase,
  jitsu: JitsuClient,
  runExperiences: () => Promise<boolean>
): Promise<void> {
  if (!(await runExperiences())) {
    return
  }
  report(jitsu, experiment)
  if (hasClientSideFormula(experiment)) {
    persistClientSideAllocation(experiment)
  }
}

export function report(jitsu: JitsuClient, experiment: UseCase) {
  maybe(() => {
    jitsu.register(
      experiment.name,
      experiment.variant,
      experiment.gaVariant!,
      experiment.gaName!,
    )

    jitsu.track(
      AnalyticEvent.USE_CASE,
      {
        use_case: experiment.name,
        use_case_variant: experiment.variant,
        ...(experiment.version ? { version: experiment.version } : {})
      },
      {
        isAudience: experiment.isAudience,
        gaName: experiment.gaName,
        gaVariant: experiment.gaVariant,
      },
    )
  })
}
