import { maybe } from '../../../shared/util'
import { getBackendHosts } from '../init'

export interface AllocatorQueryRequest {
  queries: AllocatorQuery[];
  userId: string;
  alias: string;
  timestamp: string;
}

export interface AllocatorQuery {
  name: string;
  oneOf: {
    productInfo?: ProductInfoQuery
    handlesByCollection?: string
    handlesByTag?: string
  }
}

export interface ProductInfoQuery {
  handle: string;
  includeTags?: boolean;
  includeCollections?: boolean;
  includeSales?: boolean;
}

export interface BaseResult {
  kind: string;
  cached?: boolean;
}

export interface ProductInfoResult extends BaseResult {
  handle: string;
  tags: string[];
  collections: string[];
  sales: string;
}

export interface HandlesResult extends BaseResult {
  kind: string;
  handles: string[];
}

export type QueryResult = ProductInfoResult | HandlesResult | undefined

export interface AllocatorQueryResponse {
  results: Record<string, QueryResult>;
}

export async function allocatorQuery(request: AllocatorQueryRequest, secret: string): Promise<AllocatorQueryResponse> {
  const out: AllocatorQueryResponse = { results: {} }
  const requestClone = { ...request }
  requestClone.queries = filterCachedQueries(request, out)

  if (requestClone.queries.length > 0) {
    const { query, hashedQuery } = encodeAllocatorQuery(requestClone, secret)
    const url = getBackendHosts(window.loomi_ctx.env!).cfgUrl.replace("/allocate", `/query?q=${query}&h=${hashedQuery}`)
    const resp = await fetch(url)
    if (resp.ok) {
      const jsResp = await resp.json() as AllocatorQueryResponse
      if (jsResp.results) {
        Object.entries(jsResp.results).forEach(([queryName, result]) => {
          handleResultEntry(queryName, result, requestClone)
          out.results[queryName] = result
        })
      }
    }
  }

  return Promise.resolve(out)
}

export function encodeAllocatorQuery(request: AllocatorQueryRequest, secret: string, ts?: string) {
  request.timestamp = ts || `${generateDigitSequence(4)}${secret}${generateDigitSequence(5)}`
  request.alias = window.loomi_ctx.storeAlias || ''
  request.userId = window.loomi_ctx.userId || ''

  const json = JSON.stringify(request)
  const query = btoa(unescape(encodeURIComponent(json)))
  const hashedQuery = hash(query + window.loomi_ctx.userId + window.loomi_ctx.storeAlias + secret)
  return { query, hashedQuery }
}

function handleResultEntry(queryName: string, result: QueryResult, request: AllocatorQueryRequest) {
  if (result) {
    if (result.kind === 'ProductInfo') {
      result = result as ProductInfoResult
      const source = request.queries.find(q => q.name === queryName)
      if (source) {
        if (maybe(() => source.oneOf.productInfo!.includeTags, false) && !result.tags) {
          result.tags = []
        }
        if (maybe(() => source.oneOf.productInfo!.includeCollections, false) && !result.collections) {
          result.collections = []
        }
        if (maybe(() => source.oneOf.productInfo!.includeSales, false) && !result.sales) {
          result.sales = ""
        }
        sessionStorage.setItem(formatProductInfoQueryKey(result.handle), JSON.stringify(result))
      }
    } else if (result.kind === 'HandlesByCollection') {
      result = result as HandlesResult
      const source = request.queries.find(q => q.name === queryName)
      if (source) {
        const collection = maybe(() => source.oneOf.handlesByCollection, "") || ""
        sessionStorage.setItem(formatHandlesByCollectionQueryKey(collection), JSON.stringify(result))
      }
    } else if (result.kind === 'HandlesByTag') {
      result = result as HandlesResult
      const source = request.queries.find(q => q.name === queryName)
      if (source) {
        const tag = maybe(() => source.oneOf.handlesByTag, "") || ""
        sessionStorage.setItem(formatHandlesByTagQueryKey(tag), JSON.stringify(result))
      }
    }
  }
}

function filterCachedQueries(request: AllocatorQueryRequest, response: AllocatorQueryResponse): AllocatorQuery[] {
  return request.queries.filter((query) => {
    const infoReq = maybe(() => query.oneOf.productInfo)
    if (infoReq) {
      const cacheKey = formatProductInfoQueryKey(infoReq.handle)
      const cachedRawValue = sessionStorage.getItem(cacheKey)
      if (!cachedRawValue) return true
      const cachedParsedValue = JSON.parse(cachedRawValue) as ProductInfoResult
      if (isStaleProductInfo(infoReq, cachedParsedValue)) return true

      cachedParsedValue.cached = true
      response.results[query.name] = cachedParsedValue
      return false
    }

    const handleByTagReq = maybe(() => query.oneOf.handlesByTag)
    if (handleByTagReq) {
      const cacheKey = formatHandlesByTagQueryKey(handleByTagReq)
      const cachedRawValue = sessionStorage.getItem(cacheKey)
      if (!cachedRawValue) return true
      const cachedParsedValue = JSON.parse(cachedRawValue) as HandlesResult
      cachedParsedValue.cached = true
      response.results[query.name] = cachedParsedValue
      return false
    }

    const handleByCollectionReq = maybe(() => query.oneOf.handlesByCollection)
    if (handleByCollectionReq) {
      const cacheKey = formatHandlesByCollectionQueryKey(handleByCollectionReq)
      const cachedRawValue = sessionStorage.getItem(cacheKey)
      if (!cachedRawValue) return true
      const cachedParsedValue = JSON.parse(cachedRawValue) as HandlesResult
      cachedParsedValue.cached = true
      response.results[query.name] = cachedParsedValue
      return false
    }

    return true
  })
}

function isStaleProductInfo(infoReq: ProductInfoQuery, cachedValue: ProductInfoResult): boolean {
  if (infoReq.includeCollections && !cachedValue.collections) return true
  if (infoReq.includeTags && !cachedValue.tags) return true
  if (infoReq.includeSales && !cachedValue.sales) return true
  return false
}

function formatProductInfoQueryKey(handle: string): string {
  return `vsly_pi_${handle}`
}

function formatHandlesByTagQueryKey(tag: string): string {
  return `vsly_hbt_${tag}`
}

function formatHandlesByCollectionQueryKey(collection: string): string {
  return `vsly_hbc_${collection}`
}

export function generateDigitSequence(digitCount: number): string {
  return Array(digitCount).fill(0).map(() => Math.floor(Math.random() * 10)).join("")
}

function hash(s: string): number {
  return s.split('').reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0)
    return a & a
  }, 0)
}

export function generateSecret(): string {
  const ts = new Date().getTime()
  const iv = Math.round(Math.random() * 1000000)
  return `${ts + iv}`
}
