import {
  _setIsDebug,
  _setStableIds,
  AllMutationKind,
  AutomationChange,
  CompoundChange,
  DeclarativeBlock,
  defaultMutationReq,
  FontChange,
  HtmlMutationKind,
  MoveChange,
  mutationCommands,
  MutationController,
  MutationRequest,
  mutationRequests, PageRedirectChange,
  VisualEditChange,
  WidgetChange,
} from './models'
import { awaitCondition, maybe } from '../../../shared/helpers'
import { error, warn } from '../common/log'
import {
  removeSelectorFromElementsSetsRetriesWatch,
  retryRefreshElementsSets,
} from './strategy_retry_refresh_elements'
import { buildWidgetContext, mergeRequestEnvWith } from './utils'
import { newReplaceHtmlCommand } from './commands/replace_html'
import { Command } from './commands/command'
import { newAppendHtml } from './commands/append_html'
import { newAppendCssCommand } from './commands/append_css'
import { newAppendJavascriptCommand } from './commands/append_js'
import { newAppendFontCommand } from './commands/append_font'
import { newCompoundCommand } from './commands/compound'
import { newWidgetCommand } from './commands/widget'
import { newMoveHtmlCommand } from './commands/move_html'
import { newAutomationCommand } from './commands/automate'
import { newVisualEditCommand } from './commands/visual_edit'
import { newPageRedirectCommand } from './commands/page_redirect'

let observer: MutationObserver

export async function initGlobalObserver(debug?: boolean, stableIds?: boolean) {
  await awaitCondition(
    () => !!document && !!document.body && !!document.querySelector,
    100,
    100
  )
  connectGlobalObserver()
  if (debug) {
    _setIsDebug(debug)
  }
  if (stableIds) {
    _setStableIds(stableIds)
  }
}

export function connectGlobalObserver() {
  /* istanbul ignore next */
  if (typeof document === 'undefined') return
  if (!observer) {
    observer = new MutationObserver(refreshAllElementSets)
  }

  refreshAllElementSets()
  const targetElement = maybe(() => window.vslyIntegrationType, `static`) === `spa` ? document : document.body
  observer.observe(targetElement, {
    childList: true,
    subtree: true,
    characterData: true,
    attributes: false,
  })
}

export function disconnectGlobalObserver() {
  observer && observer.disconnect()
}

function refreshAllElementSets() {
  mutationRequests
    .filter(m => !m.isApplied)
    .forEach(m => {
      try {
        const elem = maybe(
          () => document.body.querySelector(m.selector) as Element
        )
        if (!elem) {
          retryRefreshElementsSets(
            m.selector,
            elem,
            refreshAllElementSets,
            100,
            10
          )
          return
        }
        if (mutationCommands.has(m.id)) return

        const cmd = resolveCommandFromRequest(m, elem)
        if (cmd) {
          mutationCommands.set(m.id, cmd)
          m.isApplied = true
        }
      } catch (ex) {
        warn(
          `failed to resolve command from mutation request with exception.`,
          ex,
          m
        )
      }
    })
  enqueueDomChanges()
}

function resolveCommandFromRequest(
  req: MutationRequest,
  elem: Element
): Command | undefined {
  if (req.kind === 'replace') {
    return newReplaceHtmlCommand(
      req.id,
      req.selector,
      elem,
      req.value as string,
      req.id
    )
  } else if (req.kind === 'appendBefore' || req.kind === 'appendAfter') {
    return newAppendHtml(
      req.id,
      req.selector,
      req.value as string,
      req.kind,
      req.id
    )
  } else if (req.kind === 'appendCss') {
    return newAppendCssCommand(
      req.id,
      req.selector,
      req.value as string,
      req.id
    )
  } else if (req.kind === 'appendJs') {
    return newAppendJavascriptCommand(
      req.id,
      req.selector,
      req.value as string,
      req.id,
      req.options,
    )
  } else if (req.kind === 'appendFont') {
    const fontChange = JSON.parse(req.value as string) as FontChange
    if (fontChange && fontChange.family && fontChange.weights) {
      return newAppendFontCommand(req.id, fontChange, req.id)
    }
  } else if (req.kind === 'compound') {
    const val = (req.value as MutationRequest[])
      .map(m => {
        m.options = req.options
        return resolveCommandFromRequest(m, elem)
      })
      .filter(m => m)
    return newCompoundCommand(req.id, val as Command[], req.id)
  } else if (req.kind === 'widget') {
    const change = maybe(() => req.block!.value as WidgetChange)
    const id = maybe(() => change!.env.sectionId.substring(1), req.id)
    return newWidgetCommand(
      change!.htmlKind,
      id,
      req.selector,
      change!.widgetId,
      change!.env,
      id,
      change!.version
    )
  } else if (req.kind === `moveElem`) {
    const change = maybe(() => req.block!.value as MoveChange)
    return newMoveHtmlCommand(
      req.id,
      req.selector,
      change!.destSelector,
      maybe(() => change!.htmlKind)!
    )
  } else if (req.kind === `automation`) {
    const change = maybe(() => req.block!.value as AutomationChange)
    return newAutomationCommand(change!.steps)
  } else if (req.kind === `visualEdit`) {
    const change = maybe(() => req.block!.value as VisualEditChange)
    return newVisualEditCommand(req.id, change!.changes)
  } else if (req.kind === `pageRedirect`) {
    const change = maybe(() => req.block!.value as PageRedirectChange)
    if (change) {
      return newPageRedirectCommand(req.id, change, req.options)
    }
  }
  return undefined
}

let raf = false

function enqueueDomChanges() {
  if (!raf) {
    raf = true
    requestAnimationFrame(applyMutations)
  }
}

function applyMutations() {
  mutationCommands.forEach(cmd => {
    try {
      if (cmd.isApplied()) {
        cmd.redoIfNeeded()
      } else {
        cmd.do()
      }
      // debug(`mutation:`, cmd, ` applied successfully`)
    } catch (ex) {
      error(`failed to apply mutation command with exception:`, ex, cmd)
    }
  })

  raf = false
}

export function replaceHtml(
  selector: string,
  replacement: string
): MutationController {
  const req = defaultMutationReq('replace', selector, replacement)
  return appendMutationRequest(req)
}

export function appendHtmlBefore(
  selector: string,
  addition: string
): MutationController {
  const req = defaultMutationReq('appendBefore', selector, addition)
  return appendMutationRequest(req)
}

export function appendHtmlAfter(
  selector: string,
  addition: string
): MutationController {
  const req = defaultMutationReq('appendAfter', selector, addition)
  return appendMutationRequest(req)
}

export function appendCss(selector: string, css: string): MutationController {
  const req = defaultMutationReq('appendCss', selector, css)
  return appendMutationRequest(req)
}

export function appendJs(selector: string, js: string): MutationController {
  const req = defaultMutationReq('appendJs', selector, js)
  return appendMutationRequest(req)
}

export function appendFont(
  family: string,
  weights: number[]
): MutationController {
  const jsonStr = JSON.stringify({ family, weights } as FontChange)
  const req = defaultMutationReq('appendFont', 'div', jsonStr)
  return appendMutationRequest(req)
}

export function mutateCompound(
  kind: HtmlMutationKind,
  selector: string,
  html: string,
  css: string,
  js: string
): MutationController {
  const cmds = prepareCompoundCommands(kind, selector, html, css, js)
  const req = defaultMutationReq('compound', selector, cmds)
  return appendMutationRequest(req)
}

export interface MutateDeclarativeOptions {
  experienceId?: string;
  variantId?: string;
  publishedAt?: number;
  version?: number;

  gaExperienceName?: string;
  gaVariantName?: string
}

export function mutateDeclarative(
  block: DeclarativeBlock,
  options?: MutateDeclarativeOptions
): MutationController {
  const typesToCopyBlock: AllMutationKind[] = [
    `moveElem`,
    `automation`,
    `visualEdit`,
  ]
  if (block.kind === 'compound') {
    const values = block.value as CompoundChange
    const cmds = prepareCompoundCommands(
      values.htmlKind,
      block.selector,
      values.html,
      values.css,
      values.js
    )
    const req = defaultMutationReq(block.kind, block.selector, cmds)
    req.options = options
    const controller = appendMutationRequest(req)
    controller.htmlId = cmds[0].id
    if (cmds[1]) controller.cssId = cmds[1].id
    if (cmds[2]) controller.jsId = cmds[2].id
    return controller
  } else if (block.kind === `widget`) {
    const req = defaultMutationReq(block.kind, block.selector, '')
    req.options = options
    req.block = block
    const widgetCtx = buildWidgetContext(req, options)
    mergeRequestEnvWith(req, widgetCtx)

    const controller = appendMutationRequest(req)
    controller.htmlId = req.id
    return controller
  } else if (block.kind === `pageRedirect`) {
    const req = defaultMutationReq(block.kind, block.selector, '')
    req.options = options
    req.block = block
    return appendMutationRequest(req)
  } else if (typesToCopyBlock.includes(block.kind)) {
    const req = defaultMutationReq(block.kind, block.selector, '')
    req.options = options
    req.block = block
    const controller = appendMutationRequest(req)
    controller.htmlId = req.id
    return controller
  }

  const req = defaultMutationReq(
    block.kind,
    block.selector,
    block.value as string
  )
  req.options = options
  return appendMutationRequest(req)
}

export function revertAllMutations(): void {
  Array.from(mutationCommands.keys()).forEach(mutationId => {
    revertMutation(mutationId)
  })
}

function revertMutation(mutationId: string): any {
  try {
    const cmd = mutationCommands.get(mutationId)
    const result = cmd && cmd.undo()
    mutationCommands.delete(mutationId)

    const idx = mutationRequests.findIndex(req => req.id == mutationId)
    removeSelectorFromElementsSetsRetriesWatch(
      maybe(() => mutationRequests[idx].selector)!
    )
    mutationRequests.splice(idx, 1)
    enqueueDomChanges()
    return result
  } catch (_) {
    return undefined
  }
}

function appendMutationRequest(req: MutationRequest): MutationController {
  if (req.selector === `div`) {
    req.selector = `body div`
  }
  mutationRequests.push(req)
  refreshAllElementSets()

  return {
    revert: () => {
      return revertMutation(req.id)
    },
    mutationId: req.id,
  }
}

function prepareCompoundCommands(
  kind: 'replace' | 'appendBefore' | 'appendAfter',
  selector: string,
  html: string,
  css: string,
  js: string
) {
  const cmds = []

  if (html && html !== '') {
    cmds.push(defaultMutationReq(kind, selector, html))
  }

  if (css && css !== '') {
    cmds.push(defaultMutationReq('appendCss', selector, css))
  }

  if (js && js !== '') {
    cmds.push(defaultMutationReq('appendJs', selector, js))
  }
  return cmds
}

export default {
  replaceHtml,
  appendHtmlAfter,
  appendHtmlBefore,
  appendCss,
  appendJs,
  mutateCompound,
  revertMutation,
  revertAllMutations,
  appendFont,
  mutateDeclarative,
  initGlobalObserver,
  connectGlobalObserver,
  disconnectGlobalObserver,
}
