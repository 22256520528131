import { maybe, reportError } from './util'

let err: Error
function getScriptUrl() {
  try {
    const scripts = document.getElementsByTagName('script')
    let myScript: any
    for (let i = 0; i < scripts.length; i++) {
      const script = maybe(() => scripts[i])
      if (
        maybe(() => script!.src.includes('https://sdk.loomi-prod.xyz/')) ||
        maybe(() => script!.src.includes('https://live.visually-io.com/')) ||
        maybe(() => script!.src.includes('https://sdk.loomi-stg.xyz/'))
      ) {
        myScript = script
        break
      }
    }
    return new URL(myScript!.src)
  } catch (e) {
    err = e as Error
    throw e
  }
}

export function parseScriptParams() {
  const dontStart = { apiKey: '', storeAlias: '', env: 0, shouldStart: false }
  try {
    if (window.location.search.includes('lmi_no_init=1')) {
      return dontStart
    }
    if (maybe(() => window.loomi_ctx.jitsuKey)) {
      const { jitsuKey, storeAlias, env } = window.loomi_ctx
      const shouldStart = !!jitsuKey && !!env && !!storeAlias
      return { apiKey: jitsuKey, env, storeAlias, shouldStart }
    }

    const url = getScriptUrl()
    const apiKey = url.searchParams.get('k')
    const storeAlias = url.searchParams.get('s')
    const env = parseInt(url.searchParams.get('e') || '')
    const shouldStart = !!apiKey && !!env && !!storeAlias
    return { apiKey, env, storeAlias, shouldStart }
  } catch (e) {
    maybe(() => logBootstrapError(e))
    return dontStart
  }
}

function logBootstrapError(e: any) {
  const thrown = maybe(() => `${(e as any).toString()}`)
  const originStacktrace = maybe(() => `${err.stack}`)
  const originMessage = maybe(() => `${err.message}`)
  reportError()(
    `vsly script bootstrap failed \n with err: ${thrown}\n ${originStacktrace}\n ${originMessage}`
  )
}

export function antiFlickerReveal() {
  try {
    const fn = maybe(() => window.vslyAntiFlickerReveal, undefined)
    if (fn) {
      fn()
    }
  } catch (ex: any) {
    reportError()(`vsly script antiFlickerReveal failed with err: ${ex.toString()}`)
  }
}
