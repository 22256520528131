import { LoadingEnv, Product, ProductRecommendationsResponse, RecommendationOptions } from './recommendations_models'
import { parseCartItems } from '../../targeting-context'
import { getSessionProducts } from '../../targeting-context/products_viewed_in_session'
import { hash, WindowCache } from '../../common/cache'
import { WidgetContext } from '../models'
import { getPurchasedProducts } from '../../targeting-context/purchased_products'
import { getCart, getCartItemHistory } from '../../targeting-context/cart'
import { getGoogleCDN, maybe } from '../../../../shared/util'
import { getCurrentProductDetails } from '../../../shopify/current-product'
import { error } from '../../common/log'
import { isFbEnabled } from '../../../../shared/fbits'

const recsCacheName = 'recs_cache'
const recsCacheTTL = 5 * 60 * 1000 + 1
const recsCache = new WindowCache<ProductRecommendationsResponse>(
  recsCacheName,
  recsCacheTTL
)

function encode(options: RecommendationOptions) {
  return btoa(
    unescape(
      encodeURIComponent(
        JSON.stringify({
          ...options,
        }),
      ),
    ),
  )
}

const isFreshlyAddedToCart = () => {
  return maybe(() => (+Date.now() - window.loomi_ctx!.last_vid_atc!.ts) < 5000)
}
export const recommendProducts = async (
  loadingEnv?: LoadingEnv,
  widgetCtx?: WidgetContext
): Promise<Array<Product>> => {
  const empty =
    Promise.resolve([])
  try {
    if (!loadingEnv) {
      return empty
    }
    let options = maybe(() => loadingEnv.recommendationOptions)!!
    if (!options) {
      return empty
    }
    setProductId(options)
    options.cartItems = takeIds(parseCartItems())
    options.currency = maybe(() => getCart()!.currency || 'USD', 'USD')
    options.cart_total = maybe(() => getCart()!.total_price, 0)
    if (isFreshlyAddedToCart()) {
      options.last_vid_atc = window.loomi_ctx.last_vid_atc
    }
    options.respSize = maybe(() => window.visually.recsPerVariant![widgetCtx!.variantId!])!

    const CART_ITEMS = "CART_ITEMS"

    if (!![
      'RECENTLY_VIEWED',
      'VIEWED_WITH_RECENTLY_VIEWED']
      .find(x => hasRecType(options, x))) {
      options.recentlyViewed = readRecentlyViewed()
    }
    const purchasedProducts = getPurchasedProducts()
    if (purchasedProducts.length > 0) {
      options.pastPurchases = purchasedProducts
    }
    if (hasRecType(options, CART_ITEMS) || hasLatestLayeredRulingLatestATC(options)) {
      options.cartHistory = getCartItemHistory()
    }

    options.userId = maybe(() => window.loomi_ctx.userId, '')!

    if (widgetCtx) {
      options.widgetContext = widgetCtx
    }
    if (maybe(() => !!window.loomi_ctx.storeAlias)) {
      options.storeAlias = window.loomi_ctx.storeAlias!
    }
    options.locale = getLocale()
    options.country = getCountry()
    options = await updateRecRequestHook(widgetCtx, options) as RecommendationOptions
    const query = encode(options)

    const queryHash = hash(query)
    let response = recsCache.get(queryHash)
    if (!response) {
      response = await callRecsApi(queryHash, query)
      maybe(() => [optimizeImageWidth, window.loomi_api.onRecsResp].forEach(f => f && f(response)))
      recsCache.set(queryHash, response)
    }

    return maybe(() => response!.products)!
  } catch (e) {
    error('loomi-widget,recProducts failed:', e)
    return empty
  }
}
export function readRecentlyViewed() {
  return getSessionProducts().filter(x => !!x.productId).map(x => {
    return ({
      productId: `${x.productId}`,
      variantId: `${x.variantId || ''}`,
      ...(!!x.ts ? { ts: x.ts } : {})
    })
  })
}

export function takeIds(
  x: {
    productId: any;
    variantId: any;
    quantity?: number;
  }[]
) {
  return maybe(() =>
    x
      .filter(x => !!x.productId)
      .map(x => {
        return ({
          productId: `${x.productId}`,
          variantId: `${x.variantId}`,
          quantity: x.quantity || 1,
        })
      })
  )
}

export function setProductId(options: RecommendationOptions) {
  const { productId, variantId } = getCurrentProductDetails()
  if (!!productId || !!variantId) {
    options.productId = { productId, variantId }
  }
}

function hasRecType(options: RecommendationOptions, recType: string) {
  return options.type === recType ||
    maybe(() => options!.strategyPerSlot!.find(s => s.strategy === recType)) ||
    maybe(() => !!Object.values(options.conditions).find((v: any) => v.qbProps.envKey === recType)) ||
    maybe(() =>
      !!options.layeredRuling!.find(s => s.strategy === recType ||
      !!maybe(() => s.strategyPerSlot!.slots.find(s => s.strategy === recType))))
}

export function optimizeImageWidth(response: ProductRecommendationsResponse | undefined) {
  if (isFbEnabled('opt-img')) {
    const width = getInitialImageWidth()
    response!.products.forEach((product) => {
      const url = new URL(product.image.src)
      url.searchParams.set('width', `${Math.min(width, product.image.width || 1280)}`)
      product.image.src = url.href
    })
  }
}

function getInitialImageWidth() {
  const w = window.innerWidth
  return w <= 480 ? 480 : w <= 768 ? 640 : w <= 1024 ? 960 : 1280
}
function getLocale() {
  return maybe(() => window.Shopify.locale) || maybe(() => window.visually.locale)
}
function getCountry() {
  return maybe(() => window.Shopify.country) || maybe(() => window.visually.country)
}

function uniqWidgetKey(widgetCtx: WidgetContext | undefined) {
  return widgetCtx!.widgetId! + widgetCtx!.experienceId! + widgetCtx!.variantId! + widgetCtx!.sectionId!
}

function updateRecRequestHook(widgetCtx: WidgetContext | undefined, options: RecommendationOptions): Promise<any> | any {
  const fnMap = maybe(() => window.loomi_api.updateRecsOpts!)!
  const opts1 = maybe(() => fnMap.get(uniqWidgetKey(widgetCtx))(options), options)
  return maybe(() => fnMap.get(window.loomi_ctx.storeAlias)(opts1), opts1)
}
function hasLatestLayeredRulingLatestATC(options: RecommendationOptions) {
  return maybe(() => !!options.layeredRuling!.find(r => r.ruleCond!.itemSelection == 'latest_added'))
}

const pendingRequests = new Map()

const callRecsApi = (cacheKey: string, query: string): Promise<ProductRecommendationsResponse> => {
  if (isFbEnabled("coalesce-recs") && pendingRequests.has(cacheKey)) { // coalesce concurrent requests
    return pendingRequests.get(cacheKey)
  }
  const fetchImpl = window.vslyNativeFetch || window.fetch
  const domain = getGoogleCDN(window.loomi_ctx.env)
  const requestPromise = (async () => {
    try {
      const resp = await fetchImpl(
        `https://${domain}/api/recommendations/web/public/v2/recommend?q=${query}`
      )
      return await resp.json()
    } finally {
      pendingRequests.delete(cacheKey)
    }
  })()
  pendingRequests.set(cacheKey, requestPromise)

  return requestPromise
}
