import { appendFont } from '../dom_mutator'
import { UseCase } from '../../../shared/types'
import { FontChange, WidgetChange } from '../dom_mutator/models'
import { maybe } from '../../../shared/helpers'
import { error } from './log'

const customFonts = [
  'Roboto',
  'Open Sans',
  'Fredoka',
  'Fredoka',
  'Smooch Sans',
  'Rubik',
  'Lato',
  'Poppins',
  'Oswald',
  'League Spartan',
  'Noto Sans',
  'Raleway',
  'Merriweather',
  'Playfair Display',
  'Inter',
  'JetBrains Mono',
  'Jetbrains Mono',
]

export function installCustomFonts(experiments: Array<UseCase>) {
  try {
    resolveUsedFonts(experiments).forEach(({ family, weights }) =>
      appendFont(family, weights)
    )
  } catch (ex) {
    error('failed to install custom fonts', ex)
  }
}

function installAllFonts() {
  customFonts.forEach(family => {
    appendFont(family, [100, 200, 300, 400, 500, 600, 700, 800, 900])
  })
}

function isCustomFont(family: string): boolean {
  return customFonts.includes(family)
}

export function resolveUsedFonts(experiments: Array<UseCase>): FontChange[] {
  const changes = experiments
    .flatMap(exp => maybe(() => exp.code))
    .filter(code => !!code)
  const widgetChanges = changes.filter(ch => ch!.kind === 'widget')
  const flattenEnvs = widgetChanges.map(ch =>
    flatten((ch!.value as WidgetChange).env)
  )
  const allFonts = flattenEnvs.flatMap(env => {
    const filtered = Object.entries(env)
      .filter(
        ([key]) => key.includes('fontFamily') || key.includes('fontWeight')
      )
      .map(item => item[1])
    const res = []
    for (let i = 0; i < filtered.length; i += 2) {
      res.push({
        family: filtered[i],
        weight:
          parseInt(
            maybe(() => filtered[i + 1]),
            10
          ) || 500,
      })
    }
    return res
  })

  const grouped = allFonts.reduce(
    (groups, current: { family: string; weight: number }) => {
      const name = current.family
      // @ts-ignore
      const group = groups[name] || (groups[name] = new Set<number>())
      group.add(current.weight)
      return groups
    },
    {}
  )

  return Object.entries(grouped)
    .filter(([family]) => isCustomFont(family))
    .map(([family, weights]) => {
      return {
        family,
        weights: Array.from(weights as Set<number>).sort(),
      }
    })
}

export function flatten(object: any): Record<string, any> {
  const result = {}
  for (const i in object) {
    if (typeof object[i] === 'object' && !Array.isArray(object[i])) {
      const temp = flatten(object[i])
      for (const j in temp) {
        // @ts-ignore
        result[`${i}.${j}`] = temp[j]
      }
    } else {
      // @ts-ignore
      result[i] = object[i]
    }
  }
  return result
}

export default {
  installAllFonts,
}
