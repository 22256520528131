import { ProductSeenInSession } from './index'
import { maybe } from '../../../shared/util'

const localStorageKey = 'loomi_session_products'

export function getSessionProducts(): Array<ProductSeenInSession> {
  const sessionProducts = maybe(() => window.loomi_ctx.sessionProducts)
  if (sessionProducts) {
    return sessionProducts
  }
  const item = localStorage.getItem(localStorageKey)
  const ret = item ? (JSON.parse(item) as Array<ProductSeenInSession>) : []
  return maybe(() => ret
    .filter(x => x.handle != '')
    .reverse()
    .slice(0, 20))!
}
