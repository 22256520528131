import { maybe } from '../../../shared/helpers'

export class Cacheable<T> {
  protected _value: T
  protected _addedAt: number

  constructor(value: T, addedAt?: number) {
    this._value = value
    this._addedAt = addedAt || new Date().getTime()
  }

  public value(): T {
    return this._value
  }

  public addedAt(): number {
    return this._addedAt
  }
}

interface Backend<T> {
  get(key: string): Cacheable<T> | undefined;
  set(key: string, value: Cacheable<T>): void;
  remove(key: string): void;
}

abstract class Cache<T> {
  private readonly backend: Backend<T>
  protected ttlMillis: number

  protected constructor(backend: Backend<T>, ttlMillis: number) {
    this.backend = backend
    this.ttlMillis = ttlMillis
  }

  protected isValid(value: Cacheable<T> | undefined): boolean {
    if (!value) return false
    return new Date().getTime() - value.addedAt() <= this.ttlMillis
  }

  public get(key: string): T | undefined {
    const value = this.backend.get(key)
    const isValid = this.isValid(value)
    if (!!value && isValid) {
      return value.value()
    }

    if (!isValid) {
      this.backend.remove(key)
    }

    return undefined
  }

  public set(key: string, value: T, addedAt?: number) {
    const cacheable: Cacheable<T> = new Cacheable<T>(value, addedAt)
    this.backend.set(key, cacheable)
  }
}

class WindowBackend<T> implements Backend<T> {
  private readonly namespace: string

  constructor(namespace: string) {
    this.namespace = `vsly_cache_${namespace}`
    // @ts-ignore
    window[this.namespace] = {}
  }

  get(key: string): Cacheable<T> | undefined {
    // @ts-ignore
    return maybe(() => window[this.namespace][key])
  }

  remove(key: string): void {
    // @ts-ignore
    maybe(() => delete window[this.namespace][key])
  }

  set(key: string, value: Cacheable<T>): void {
    // @ts-ignore
    window[this.namespace][key] = value
  }
}

export class WindowCache<T> extends Cache<T> {
  constructor(name: string, ttlMillis: number) {
    super(new WindowBackend(name), ttlMillis)
  }
}

export const hash = function(str: string, seed = 8397271938200529): string {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return `${4294967296 * (2097151 & h2) + (h1 >>> 0)}`
}
