import { CartBase } from '../../../shared/shopifyTypes'
import { reportError } from '../common/jitsu'
import { maybe } from '../../../shared/util'

const cartKey = 'loomi-cart'
const cartHistoryKey = 'loomi-cart-history'

export function getCart(): CartBase | undefined {
  const cart = maybe<CartBase>(() => window.loomi_ctx.cart!)
  if (!!cart) {
    return cart
  }
  const item = localStorage.getItem(cartKey)
  if (!!item) {
    try {
      return JSON.parse(item as string) as CartBase
    } catch (e) {
      reportError()(`failed to parse cart, with err: ${(e as any).toString()}`)
    }
  }
  return undefined
}

export function getCartItemHistory(): Array<CartHistoryEntry> {
  return JSON.parse(localStorage.getItem(cartHistoryKey) || '[]') as Array<
  CartHistoryEntry
  >
}

export interface CartHistoryEntry {
  variant_id: string;
  product_id: string;
  timestamp: string;
  price: number;
  quantity: number;
}
