import { maybe } from '../../../shared/util'

type LmiQueryParm = string
export const lmiType: LmiQueryParm = 'lmi_type'
export const lmiFrom: LmiQueryParm = 'lmi_from'
export const lmiClass: LmiQueryParm = 'lmi_class'

export function isLoomiParam(name: string) {
  return maybe(() => [lmiClass, lmiFrom, lmiType].includes(name))
}

export const getCookieDomain = () => {
  return document.location.hostname.replace('www.', '')
}

export const setCookie = (
  name: string,
  value: string,
  expire: number,
  domain: string,
  secure: boolean
) => {
  const expireString =
    expire === Infinity
      ? ' expires=Fri, 31 Dec 9999 23:59:59 GMT'
      : '; max-age=' + expire
  document.cookie =
    encodeURIComponent(name) +
    '=' +
    value +
    '; path=/;' +
    expireString +
    (domain ? '; domain=' + domain : '') +
    (secure ? '; secure' : '')
}

export const generateRandom = () =>
  Math.random()
    .toString(36)
    .substring(2, 7)

export const parseQuery = (qs?: string) => {
  const queryString = qs || window.location.search.substring(1)
  const query: Record<string, string> = {}
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    let v = decodeURIComponent(pair[1] || '')
    if (v.endsWith('\\')) {
      v = v.replace(/.$/, '')
    }
    query[decodeURIComponent(pair[0])] = v
  }

  return query
}

const UTM_TYPES: Record<string, string> = {
  utm_source: 'source',
  utm_medium: 'medium',
  utm_campaign: 'campaign',
  utm_term: 'term',
  utm_content: 'content',
}

const CLICK_IDS: Record<string, boolean> = {
  gclid: true,
  fbclid: true,
  dclid: true,
}

export const getDataFromParams = (params: Record<string, string>) => {
  const result = {
    utm: {} as Record<string, string>,
    click_id: {} as Record<string, any>,
    lmi_params: {} as Record<string, any>,
  }
  for (const name in params) {
    if (!params.hasOwnProperty(name)) {
      continue
    }
    const val = params[name]
    const utm = UTM_TYPES[name]
    if (utm) {
      result.utm[utm] = val
    } else if (CLICK_IDS[name]) {
      result.click_id[name] = val
    } else if (isLoomiParam(name)) {
      result.lmi_params[name] = decodeURIComponent(val)
    }
  }
  return result
}

//2020-08-24T13:42:16.439Z -> 2020-08-24T13:42:16.439123Z
export const reformatDate = (strDate: string) => {
  const end = strDate.split('.')[1]
  if (!end) {
    return strDate
  }
  if (end.length >= 7) {
    return strDate
  }
  return strDate.slice(0, -1) + '0'.repeat(7 - end.length) + 'Z'
}

function endsWith(str: string, suffix: string) {
  return str.includes(suffix, str.length - suffix.length)
}

export const getHostWithProtocol = (host: string) => {
  while (endsWith(host, '/')) {
    host = host.substr(0, host.length - 1)
  }
  if (host.indexOf('https://') === 0 || host.indexOf('http://') === 0) {
    return host
  } else {
    return '//' + host
  }
}

export function monitorInfiniteLoop(fn: Function, metric: string, intervalMillis = 2000, maxCalls = 5) {
  let lastCallTime = 0
  let callsInInterval = 0
  let alertSent = false
  return function(...args: any) {
    const now = Date.now()
    if (now - lastCallTime < intervalMillis) {
      callsInInterval += 1
      if (callsInInterval >= maxCalls && !alertSent) {
        alertSent = true
        reportMetric(metric)
      }
    } else {
      callsInInterval = 0
    }
    lastCallTime = now
    !alertSent && fn()(...args)
  }
}

export function reportMetric(
  name: string,
  kind: `counter` | `gauge` = `counter`,
  namespace: string = 'sdk',
  op = `inc`,
) {
  fetch(
    `https://logs.loomi-prod.xyz/af3a0f80-908b-4602-9c31-535a453d2b3a/collect/${kind}/${namespace}/${name}/${op}`,
    {
      method: `POST`,
      body: JSON.stringify({
        alias: window.loomi_ctx.storeAlias,
      }),
      headers: {
        'Content-Type': `application/json; charset=UTF-8`,
      },
    },
  )
}
