import { HtmlMutationKind } from '../models'
import { Command } from './command'
import { awaitCondition, maybe } from '../../../../shared/helpers'

const ORIGINAL_SELECTOR_ATTR = `vsly-orig-sel`

interface OldElementInfo {
  parentNode?: HTMLElement | null;
  nextSib?: Element | null;
  prevSib?: Element | null;
  display: string;
}

export function newMoveHtmlCommand(
  id: string,
  fromSelector: string,
  toSelector: string,
  moveKind: HtmlMutationKind
): Command {
  let isApplied = false
  let oldInfo: OldElementInfo = { display: `` }
  let srcElement: HTMLElement | null = null
  let destElement: HTMLElement | null = null

  const _do = () => {
    if (isApplied) return
    awaitCondition(
      () => {
        srcElement = document.querySelector(fromSelector) as HTMLElement
        destElement = document.querySelector(toSelector) as HTMLElement
        return !!srcElement && !!destElement
      },
      50,
      500
    ).then(() => {
      srcElement = document.querySelector(fromSelector) as HTMLElement
      destElement = document.querySelector(toSelector) as HTMLElement
      move()
    })
  }

  const move = () => {
    if (!!destElement && !!srcElement) {
      oldInfo = {
        display: destElement.style.display,
        parentNode: srcElement.parentElement,
        nextSib: srcElement.nextElementSibling,
        prevSib: srcElement.previousElementSibling,
      }

      if (moveKind === `appendBefore`) {
        destElement.insertAdjacentElement(`beforebegin`, srcElement)
      } else if (moveKind === `appendAfter`) {
        destElement.insertAdjacentElement(`afterend`, srcElement)
      } else if (moveKind === `replace`) {
        destElement.style.display = `none!important`
        destElement.insertAdjacentElement(`afterend`, srcElement)
      }
      srcElement.setAttribute(ORIGINAL_SELECTOR_ATTR, fromSelector)
      isApplied = true
    }
  }

  const _undo = () => {
    if (!isApplied) return

    if (!!destElement && !!srcElement) {
      if (oldInfo && oldInfo.prevSib) {
        oldInfo.prevSib.insertAdjacentElement(`afterend`, srcElement)
      } else if (oldInfo && oldInfo.nextSib) {
        oldInfo.nextSib.insertAdjacentElement(`beforebegin`, srcElement)
      } else if (oldInfo && oldInfo.parentNode) {
        oldInfo.parentNode.appendChild(srcElement)
      }

      if (moveKind === `replace`) {
        destElement.style.display = oldInfo.display
      }

      srcElement.removeAttribute(ORIGINAL_SELECTOR_ATTR)

      isApplied = false
    }
  }

  const _redo = () => {
    if (
      // @ts-ignore
      !maybe(() => srcElement.hasAttribute(ORIGINAL_SELECTOR_ATTR)) ||
      !document.body.contains(srcElement)
    ) {
      isApplied = false
      _do()
      return true
    }
    return false
  }

  return {
    id,
    isApplied: () => isApplied,
    kind: `moveElem`,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
  }
}
