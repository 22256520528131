import { dbRead } from '../../../shared/indexdb/db'
import { visitedPagesKey } from '../common/storage_keys'
import { VisitedPage } from '../../../shared/types'
import { maybe } from '../../../shared/util'

export function PageVisit(
  term: string,
  timeframe: string,
  _: 'contains' | 'matches',
  includeQuery: boolean,
): Promise<boolean> {
  const [lastN, interval] = timeframe.split(",")
  return new Promise(resolve => {
    dbRead(visitedPagesKey).then((v?: { visits?: Array<VisitedPage> }) => {
      for (const visit of filterByTime(v, lastN, interval)) {
        if (includeQuery || valueIsUrlWithQuery(term)) {
          if (isPathWithQueryMatches(visit, term)) {
            resolve(true); return
          }
        } else {
          if (isPathMatches(visit, term)) {
            resolve(true); return
          }
        }
      }
      resolve(false)
    })
  })
}

function isPathMatches(visit: VisitedPage, page: string) {
  return !![cleanUrl(visit),
    handleProductInCollection(cleanUrl(visit))].find(x => x.includes(page))
}

function isPathWithQueryMatches(visit: VisitedPage, term: string): boolean {
  const search = visit.search || ''
  const url = cleanUrl(visit) + search
  return !![url, handleProductInCollection(url)].find(x => x.includes(withoutTrailingSlash(term)))
}

export function withoutLocalizationPrefix(urlStr: string) {
  const url = maybe(() => new URL(urlStr))
  if (!url) {
    return urlStr
  }
  const pathname = url.pathname
  const pathParts = pathname.split('/')
  const firstPathFragment = pathParts[1]
  const isLocalized = /^[a-z]{2}-[a-z]{2}$/.test(firstPathFragment)
  if (isLocalized) {
    url.pathname = pathParts.slice(2).join('/')
  }
  return withoutTrailingSlash(url.href)
}

function cleanUrl(visit: VisitedPage) {
  return withoutTrailingSlash(withoutLocalizationPrefix(visit.name))
}

function filterByTime(v: { visits?: Array<VisitedPage> } | undefined, lastN: string, interval: string) {
  const now = new Date().valueOf()
  return (maybe(() => v!.visits!, []) || [])!
    .filter(v => {
      const vof = new Date(v.ts).valueOf()
      const hour = 3.6e+6
      const parsed = parseInt(lastN)
      switch (interval.replaceAll('"', '')) {
        case 'hours':
          return vof > now - parsed * hour
        case 'weeks':
          return vof > now - parsed * hour * 24 * 7
        case 'days':
          return vof > now - parsed * hour * 24
        default:
          return false
      }
    })
}

export function withoutTrailingSlash(url: string) {
  const name = maybe(() => new URL(url))
  if (!name || !name.pathname.endsWith("/")) {
    return url
  }
  if (name.pathname === "/") {
    return name.href.slice(0, -1)
  }
  name.pathname = name.pathname.slice(0, -1)
  return name.href
}

function handleProductInCollection(url: string) {
  if (url.includes('/products/') && url.includes('/collections/')) {
    const regex = /\/collections\/[^/]+/
    return url.replace(regex, '')
  }
  return url
}

function valueIsUrlWithQuery(value: string) {
  return maybe(() => !!new URL(value).search)
}
