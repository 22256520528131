import { Configuration, Context } from '../../../shared/types'
import { isFbEnabled } from '../../../shared/fbits'
import { awaitCondition, hidePreviewBarIframe, maybe } from '../../../shared/helpers'
import { isInShopifyThemeEditor, REDIRECT_BACK_TO_MANI, redirectTest } from './utils'
import { THEME_TEST_KEY } from '../../../shared/consts'

export async function handleThemeTestCleanup(configuration: Configuration) {
  try {
    if (configuration.noActiveThemeTests) { // 3 day cleanup
      if (await themeTestEntryPoint(0)) {
        return
      }
      doCleanupRedirect()
    } else if (themeTestStopped(configuration)) {
      doCleanupRedirect()
    }
  } catch (e) {}
}

export async function themeTest(
  ctx: Context,
  targetThemeId?: number,
  redirectAfter = 10,
) {
  if (isFbEnabled('kill-theme-testing')) return
  if (await themeTestEntryPoint(targetThemeId)) {
    return
  }
  persistThemeTestId(ctx, targetThemeId)
  redirectTest(ctx, targetUrl(targetThemeId).href, redirectAfter, true, 'session')
}

const themeTestEntryPoint = async(targetThemeId?: number) => {
  if (isDisabled()) { // disabled shopify editor + or if special query param exists
    return true
  }
  hidePreviewBarIframe() // always hide preview bar when a test is active / or in three day cleanup period
  const currentTheme = await getCurrentTheme()
  const notMainTheme = !isMain(currentTheme)
  const themeNotIncludedInTest = maybe(() => !window.loomi_ctx.testedThemes!.includes(currentTheme.id))
  if (notMainTheme && themeNotIncludedInTest) {
    return true // don't run a redirect if it's a theme we do not test
  }
  return isOnTargetTheme(currentTheme, targetThemeId) // don't redirect if we are on target theme
}

const persistThemeTestId = (ctx: Context, targetThemeId: number | undefined) => {
  const notCleanup = (ctx._USE_CASE !== REDIRECT_BACK_TO_MANI)
  if (isNonControl(targetThemeId) && notCleanup) {
    [sessionStorage, localStorage].forEach(s => writeToStorage(s))
  }
}

export const themeTestStopped = (configuration: Configuration) => {
  return noActiveThemeTest(configuration) && gotThemeTestRedirectInThePast()
}

const targetUrl = (targetThemeId: any) => {
  const url = parseLocation()!
  url.searchParams.append('preview_theme_id', `${targetThemeId || ''}`)
  return url
}

const doCleanupRedirect = () => {
  const key = 'vsly-tts'
  if (hasSearchParam(key)) {
    return
  }
  cleanupThemeTestFlag()
  const url = targetUrl(``)
  url.searchParams.append(key, '1')
  // @ts-ignore
  document.body.style.opacity = 0
  location.replace(url.href)
}
const isDisabled = () => {
  const ignoreThemeTestKey = 'vsly_disableThemeTest'
  const hasSkipThemeTestQueryParam = hasSearchParam('disableThemeTest') || !!sessionStorage.getItem(ignoreThemeTestKey)
  if (hasSkipThemeTestQueryParam) {
    sessionStorage.setItem(ignoreThemeTestKey, '1')
  }
  return isInShopifyThemeEditor() || hasSkipThemeTestQueryParam
}

const noActiveThemeTest = (cfg: Configuration) => !(cfg.experiments || []).find(e => e.isThemeTest)
const cleanupThemeTestFlag = () => [sessionStorage, localStorage].forEach(s => s.removeItem(THEME_TEST_KEY))
const gotThemeTestRedirectInThePast = () => !![localStorage, sessionStorage].find(getFromStorage)
const awaitThemeIsAvailable = () => awaitCondition(() => maybe(() => window.Shopify.theme.id > 0)!!, 20, 150)
const isOnTargetTheme = (currentTheme: Theme, targetThemeId?: number) => (currentTheme.id === targetThemeId) || (isMain(currentTheme) && !targetThemeId)
const isMain = (currentTheme: Theme) => currentTheme.role === 'main'
const writeToStorage = (storage: Storage) => storage.setItem(THEME_TEST_KEY, '1')
const getFromStorage = (storage: Storage) => storage.getItem(THEME_TEST_KEY)
const isNonControl = (targetThemeId: number | undefined) => !!targetThemeId
const hasSearchParam = (key: string) => maybe(() => parseLocation()!.searchParams.has(key))
const parseLocation = () => maybe(() => new URL(window.location.href))
type Theme = { role: string; id: number }
const getCurrentTheme = async () => {
  await awaitThemeIsAvailable()
  return window.Shopify.theme
}
