import { maybe } from '../../../shared/helpers'

export function getHistoryArray<T>(storageKey: string) {
  return JSON.parse(localStorage.getItem(storageKey) || '[]') as Array<T>
}

export function updateHistoryArray<T>(
  newParams: Array<T> | undefined,
  oldParams: Array<T> | undefined,
  eq: (b: T) => (a: T) => boolean,
  storageKey: string
): Array<T> {
  newParams = newParams || []
  oldParams = oldParams || []
  const oldWithoutNew =
    maybe(() =>
      oldParams!.filter(oldParam => {
        return !newParams!.find(eq(oldParam))
      })
    ) || []
  const nextParams = [...(newParams), ...oldWithoutNew].slice(0, 25)
  if (nextParams.length > 0) {
    localStorage.setItem(storageKey, JSON.stringify(nextParams))
  }
  return nextParams
}
