import { Command } from './commands/command'
import { VisualEdit } from './commands/visual_edit'
import { MutateDeclarativeOptions } from './index'

export type HtmlMutationKind = 'replace' | 'appendBefore' | 'appendAfter'
export type AllMutationKind =
  | HtmlMutationKind
  | 'appendCss'
  | 'appendJs'
  | 'compound'
  | 'widget'
  | 'appendFont'
  | `moveElem`
  | `automation`
  | `visualEdit`
  | `pageRedirect`

export let _IS_DEBUG = false
export let _STABLE_IDS = false

export function _setIsDebug(value: boolean) {
  _IS_DEBUG = value
}

export function _setStableIds(value: boolean) {
  _STABLE_IDS = value
}

export interface MutationKind {
  kind: AllMutationKind;
}

export interface MutationRequest extends MutationKind {
  id: string;
  isApplied: boolean;
  selector: string;
  value: string | MutationRequest[];
  block?: DeclarativeBlock;
  options?: MutateDeclarativeOptions;
}

export function defaultMutationReq(
  kind: AllMutationKind,
  selector: string,
  value: string | MutationRequest[]
): MutationRequest {
  return {
    id: nextMutationId(),
    isApplied: false,
    kind,
    selector,
    value,
  }
}

export type MutationStrategy = (element: HTMLElement, selector: string) => void

export interface DeclarativeBlock {
  order?: number;
  kind: AllMutationKind;
  selector: string;
  value:
  | string
  | CompoundChange
  | WidgetChange
  | MoveChange
  | AutomationChange
  | VisualEditChange
  | PageRedirectChange;
}

export interface VisualEditChange {
  changes: VisualEdit[];
}

export interface AutomationChange {
  steps: AutomationStep[];
}

export interface AutomationStep {
  order: number;
  kind: AutomationStepKind;
  selector?: string;
  data?: any;
}

export type AutomationStepKind = `click` | `type` | `wait` | `scroll`

export interface MoveChange {
  htmlKind: HtmlMutationKind;
  destSelector: string;
}

export interface CompoundChange {
  htmlKind: HtmlMutationKind;
  html: string;
  css: string;
  js: string;
}

export interface WidgetChange {
  htmlKind: HtmlMutationKind;
  env: Record<string, any>;
  widgetId: string;
  version?: string;
}

export interface FontChange {
  family: string;
  weights: number[];
}

export interface PageRedirectChange {
  destUrl: string;
  redirectAfter: number;
  retainQueryParams?: boolean;
  stickinessMode?: StickinessMode;
}

export type StickinessMode = `none` | `session` | `user` | undefined

export interface MutationController {
  revert: RevertMutationFn;
  mutationId: string;
  htmlId?: string;
  cssId?: string;
  jsId?: string;
}

export interface WidgetContext {
  experienceId?: string;
  variantId?: string;
  sectionId?: string;
  widgetId?: string;
  widgetVersion?: string;
  publishedAt?: number;
}

export type RevertMutationFn = () => any

export function _replaceOuterHtml(
  element: Element,
  outerHTML: string
): Element {
  let parent = false
  let refEle: Element

  if (element.previousElementSibling !== null) {
    refEle = element.previousElementSibling as Element
  } else {
    refEle = element.parentElement as Element
    parent = true
  }

  if (!refEle) {
    return element
  }

  element.outerHTML = outerHTML
  if (parent) {
    return refEle.firstElementChild as Element
  } else {
    return refEle.nextElementSibling as Element
  }
}

let mutationIdx = Math.round(Math.random() * 10000)

export function nextMutationId(): string {
  return _STABLE_IDS ? 'lmi-000' : `lmi-${++mutationIdx}`
}

export let mutationRequests: MutationRequest[] = []
export let mutationCommands: Map<string, Command> = new Map<string, Command>()

export function clearAllMutations() {
  mutationRequests = []
  mutationCommands = new Map<string, Command>()
}

export const LOOMI_ATTR = 'lmid'
export const LOOMI_WIDGET = 'lmw'
export const LOOMI_WIDGET_VERSION = 'lmwv'
