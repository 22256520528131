import { Command, redoWhenElementIsDetached } from './command'
import { _IS_DEBUG } from '../models'
import { MutateDeclarativeOptions } from '../index'
import { injectOptionsToJavascript } from '../utils'

export function newAppendJavascriptCommand(
  id: string,
  selector: string,
  js: string,
  debugId?: string,
  options?: MutateDeclarativeOptions
): Command {
  let _debugId = debugId || id
  let isApplied = false
  let origin = document.querySelector(selector) as HTMLElement
  const scriptElement = document.createElement(`script`)
  scriptElement.setAttribute('type', 'application/javascript')

  const _do = () => {
    if (isApplied) return
    scriptElement.innerHTML = injectOptionsToJavascript(js, options)
    if (_IS_DEBUG) scriptElement.id = _debugId
    document.body.appendChild(scriptElement)
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    scriptElement.remove()
    isApplied = false
    return undefined
  }

  const _redo = () => {
    const resp = redoWhenElementIsDetached(_do, _undo, selector, origin)
    if (resp.element) origin = resp.element
    return resp.isDetached
  }

  return {
    id,
    kind: `appendJs`,
    isApplied: () => isApplied,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
    setDebugId: (debugId: string) => (_debugId = debugId),
  }
}
