type PurchasedLineItem = {
  variant_id: string;
  product_id: string;
  price: string;
  quantity: number;
}

export interface PurchasedLineItems {
  order_id?: string;
  line_items: Array<PurchasedLineItem>;
  created_at?: string;
}

const localStorageKey = 'loomi_purchased_products'

export function getPurchasedProducts() {
  return JSON.parse(localStorage.getItem(localStorageKey) || '[]') as Array<
  PurchasedLineItems
  >
}
