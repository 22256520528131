import { Command } from './command'

export function newCompoundCommand(
  id: string,
  commands: Command[],
  debugId?: string
): Command {
  let isApplied = false
  const _do = () => {
    if (isApplied) return
    commands
      .filter(cmd => !cmd.isApplied())
      .forEach(cmd => {
        if (cmd.setDebugId) cmd.setDebugId(debugId!)
        cmd.do()
      })
    isApplied = true
  }

  const _undo = () => {
    if (!isApplied) return
    commands
      .filter(cmd => cmd.isApplied())
      .reverse()
      .forEach(cmd => cmd.undo())
    isApplied = false
    return undefined
  }

  const _redo = () => {
    return (
      commands.map(cmd => cmd.redoIfNeeded()).filter(res => res)
        .length > 0
    )
  }

  return {
    id,
    isApplied: () => isApplied,
    kind: `compound`,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
  }
}
