import { maybe } from '../../../../shared/util'

export async function addToCart(
  quantity: number,
  variantId: number,
  openDrawer: boolean = true,
  properties: any = undefined
) {
  const addToCart = maybe(() => window.loomi_api.addToCart)
  if (!!addToCart) {
    await window.loomi_api.addToCart(
      quantity,
      variantId,
      openDrawer,
      properties
    )
  }
}
