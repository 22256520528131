import { AddToCartPayload, AnalyticEvent } from './events'
import { fireTargetingEvent, TargetingEventType } from '../../../shared/events'
import { CartBase, CartHistoryEntry, Item } from '../../../shared/shopifyTypes'
import { deepEqual } from '../util'
import { awaitCondition, maybe } from '../../../shared/util'
import { CartLineItemUpdateRoot, CartLinesAddRoot, CartLinesRemoveRoot, CartQueryRoot, GQLCart } from './gql'
import { getLogger } from '../log/log'
import {
  AllocatorQuery,
  AllocatorQueryRequest,
  AllocatorQueryResponse,
  ProductInfoResult,
} from '../../../sdk/core/common/allocator_query'
import { error } from '../../../sdk/core/common/log'
import { createDebouncedFn, now } from '../../../shared/helpers'
import { VSLY_CART_TOKEN_ATTR_KEY, VSLY_SID_ATTR_KEY, VSLY_UID_ATTR_KEY } from '../../../shared/consts'
import { isFbEnabled } from '../../../shared/fbits'

function awaitJitsu() {
  return awaitCondition(() => maybe(() => !!window.loomi.jitsu!)!, 100, 100)
}

export function getCart() {
  if (maybe(() => typeof window.loomi_ctx.cart == 'undefined')) {
    const item = localStorage.getItem(cartKey)
    if (!!item) {
      try {
        window.loomi_ctx = {
          ...(window.loomi_ctx || {}),
          cart: JSON.parse(item as string),
        }
      } catch (e) {
        getLogger().error('failed parsing cart', item)
      }
    }
  }
  return maybe(() => window.loomi_ctx.cart)
}

export const shopifyCartInterceptor = (
  domain: string,
  method: string,
  url: string,
  reqBody: any,
  respBody: any,
  _: 'xhr' | 'fetch',
): void => {
  if (isSameSite(domain)) {
    const METHOD = maybe(() => method.toUpperCase())
    if (METHOD === 'POST') {
      if (isAddToCartCall(url, reqBody)) {
        awaitJitsu().then(() => window.loomi.jitsu!.track(AnalyticEvent.ADD_TO_CART, eventPayload(reqBody)))
        handleCartResp(respBody, url, !url.includes("update"))
      } else if (isCartChange(url, reqBody)) {
        const payload = eventPayload(reqBody)
        const eventName = isAddOrRemFromCart(payload)
        eventName && awaitJitsu().then(() => window.loomi.jitsu!.track(eventName, payload))
        handleCartResp(respBody, url)
      }
      if (isGraphqlReq(url)) {
        onCartChange(getGqlRespCart(respBody))
      }
    } else if (METHOD === 'GET' || !METHOD) {
      if (isGetCartCall(url)) {
        if (!!respBody && isCartShape(respBody)) {
          onCartChange(respBody)
        }
      }
    }
  }
}

const debouncePDPResp = createDebouncedFn(() => document.body.dispatchEvent(new CustomEvent('pdpresp')), 500)

export const shopifyProductInterceptor = (
  domain: string,
  _: string,
  url: string,
): void => {
  const pdp = "/products/"
  const allowdList = ['/collections/', pdp]

  if (isSameSite(domain) &&
    (url || '').includes(pdp) &&
    maybe(() => !!allowdList.find(x => window.location.pathname.includes(x))) &&
    !isFbEnabled('kill-pdp-intercept')) {
    debouncePDPResp()
  }
}

export const cartKey = 'loomi-cart'

function getGqlRespCart(respBody: any) {
  const addToCart = maybe(() => (respBody as CartLinesAddRoot).data.cartLinesAdd.cart)
  const cartUpdate = maybe(() => (respBody as CartLineItemUpdateRoot).data.cartLinesUpdate.cart)
  const cartRm = maybe(() => (respBody as CartLinesRemoveRoot).data.cartLinesRemove.cart)
  const cart = maybe(() => (respBody as CartQueryRoot).data.cart)
  return transformGqlCart(cart || addToCart || cartUpdate || cartRm)
}

export async function enrichCart(cart: any): Promise<any> {
  if (maybe(() => cart.items.length <= 0)) {
    return Promise.resolve(cart)
  }

  try {
    const handles = maybe(() => Array.from(cart.items.map((i: any) => i.handle)), []) as string[]
    const queries: AllocatorQuery[] = handles.map((handle: string) => ({
      name: handle,
      oneOf: {
        productInfo: { handle, includeCollections: true, includeTags: true }
      }
    }))

    const result = await window.loomi_api.allocatorQuery({ queries } as AllocatorQueryRequest) as AllocatorQueryResponse

    cart.items = cart.items.map((i: any) => {
      i.tags = maybe(() => (result.results[i.handle]! as ProductInfoResult).tags, [])
      i.collections = maybe(() => (result.results[i.handle]! as ProductInfoResult).collections, [])
      return i
    })
  } catch (ex) {
    error("failed to enrich cart with error", ex, cart)
  }
  return Promise.resolve(cart)
}

const onCartChangeOrig = (cart: any) => {
  if (isCartChanged(getCart(), cart)) {
    // This is not necessary a feature bit controlled by unleashed
    // This will be enabled if the fb is on and the experience contains enhanced cart client-side-formulas
    if (maybe(() => window.visually.flags["sdk-enable-cart-enrichment"], false) === true) {
      enrichCart(cart).then(enrichedCart => {
        persistCart(enrichedCart)
        fireTargetingEvent(
          TargetingEventType.CART_CHANGE,
          maybe(() => window.loomi_ctx.cart),
        )
      })
    } else {
      persistCart(cart)
      fireTargetingEvent(
        TargetingEventType.CART_CHANGE,
        maybe(() => window.loomi_ctx.cart),
      )
    }
  }
}

const onCartChangeDebounced = createDebouncedFn(onCartChangeOrig, 400)

export const onCartChange = (cart: any) => {
  if (maybe(() => window.visually.flags["sdk-enable-cart-change-debounce"], false) === true) {
    onCartChangeDebounced(cart)
  } else {
    onCartChangeOrig(cart)
  }
}

function handleCartResp(respBody: any, url: string, triggerCartChange: boolean = true) {
  const triggerOnCartUpdate = !isFbEnabled('disable-update-cart-refresh') || triggerCartChange
  if (isCartShape(respBody as CartBase) && triggerOnCartUpdate) {
    onCartChange(respBody)
  }
  if (isGraphqlReq(url)) {
    onCartChange(getGqlRespCart(respBody))
  }
  if (maybe(() => !url.includes('vsly=t'))) {
    refreshCart()
  }
}

export function isAddOrRemFromCart(payload: {
  qty?: string;
  variantId?: string;
}) {
  if (maybe(() => !payload.qty) || maybe(() => !payload.variantId)) {
    return null
  }
  const currentQty = maybe(() => getCart()!.items.find(
    (x: any) => x.id == payload.variantId || x.variant_id == payload.variantId,
  )!.quantity)
  if (!currentQty) return AnalyticEvent.CHANGE_QTY
  const nextQty = parseInt(payload.qty as string)
  if (nextQty === 0) {
    return AnalyticEvent.REMOVE_FROM_CART
  }
  if (nextQty === currentQty) {
    return null
  }
  if (nextQty > currentQty) {
    return AnalyticEvent.ADD_TO_CART
  } else {
    return AnalyticEvent.REMOVE_FROM_CART
  }
}

function isGraphqlReq(url: string) {
  return url.includes('/api/') && url.endsWith('graphql.json')
}

function gqlQueryString(reqBody: any) {
  let query = ""
  if (typeof reqBody === 'object') {
    query = maybe(() => (reqBody as any).query.toLowerCase())
  } else if (typeof reqBody === 'string') {
    query = reqBody.toLowerCase()
  }
  return query
}

const isCartGraphqlUpdate = (
  url: string,
  reqBody: any,
  mutations: Array<string>,
) => {
  // https://shopify.dev/docs/api/storefront/2023-04/mutations/cartLinesAdd
  let query: string = ''
  if (isGraphqlReq(url)) {
    query = gqlQueryString(reqBody)
    return !!mutations.find(x =>
      query.includes(`mutation ${x}`),
    )
  }
  return false
}

const cartHistoryKey = 'loomi-cart-history'

function getNewCartItems(respBody: CartBase | undefined) {
  return maybe(() => respBody!.items.map(({ variant_id, product_id, price, quantity }) => {
    return {
      price,
      variant_id: `${variant_id}`,
      product_id: `${product_id}`,
      quantity,
      timestamp: isoNow(),
    }
  }), []) as Array<CartHistoryEntry>
}

function hasQuantityChanged(cart: CartBase | undefined, variant: CartHistoryEntry) {
  return maybe(() => cart!.items.find(i => `${i.variant_id}` == `${variant.variant_id}`)!.quantity < variant.quantity)
}

function addToHistory(newItems: Array<CartHistoryEntry>) {
  const cart = getCart()
  let cartHistory = getCartItemHistory()
  const historyVariants = maybe(() => cartHistory.map(x => `${x.variant_id}`))
  maybe(() => newItems.forEach(variant => {
    const idx = historyVariants!.indexOf(`${variant.variant_id}`)
    const notInHistory = idx < 0
    if (notInHistory) {
      cartHistory = [variant, ...cartHistory]
    } else if (hasQuantityChanged(cart, variant)) {
      cartHistory[idx] = { ...variant, timestamp: isoNow() }
      cartHistory = cartHistory.sort((a, b) => ts(b) - ts(a))
    }
  }))
  return cartHistory
}

export function recordCartItemsHistory(respBody: CartBase | undefined) {
  try {
    const newItems = getNewCartItems(respBody)
    const cartHistory = addToHistory(newItems)
    persist(cartHistory)
  } catch (e) {
    getLogger().error('handleCartItemsHistory', e)
  }
}

export function getCartItemHistory(): Array<CartHistoryEntry> {
  return JSON.parse(localStorage.getItem(cartHistoryKey) || '[]') as Array<
  CartHistoryEntry
  >
}

function persist(cartHistory: Array<CartHistoryEntry>) {
  localStorage.setItem(
    cartHistoryKey,
    JSON.stringify(cartHistory.slice(0, 25)),
  )
}

export function persistCart(cart: CartBase | undefined) {
  recordCartItemsHistory(cart)
  localStorage.setItem(cartKey, JSON.stringify(cart))
  window.loomi_ctx = { ...(window.loomi_ctx || {}), cart }
}

export function isSameSite(domain: string | undefined) {
  const suffix = ".myshopify.com"
  return window.location.hostname == maybe(() => domain!.split(':')[0]) ||
    maybe(() => domain!.endsWith(suffix)) ||
    domain === maybe(() => window.loomi_ctx.storeAlias!.toLowerCase().replace("_", "-") + suffix)
}

function isCartShape(_cart: CartBase) {
  return (
    maybe(() => typeof _cart.items != 'undefined') &&
    maybe(() => typeof _cart.total_price != 'undefined') &&
    maybe(() => typeof _cart.token != 'undefined')
  )
}

export function getAtcVariantId(atcBody: string | any) {
  return (
    maybe(() => decodeURIComponent(atcBody)!.split('&')!.find(x => x.split('=')[0] === 'id')!.split('=')[1].split(':')[0]) ||
      maybe(() => tryJsonParse(atcBody)!.id) || maybe(() => atcBody.get("id"))
  )
}

export function getAtcQty(body: string) {
  return (
    maybe(() => decodeURIComponent(body)!.split('&')!.find(x => x.split('=')[0] === 'quantity')!.split('=')[1]) ||
    maybe(() => tryJsonParse(body)!.quantity.toString())
  )
}

function isNan(variantId?: string) {
  return isNaN(Number(variantId))
}

export function eventPayload(
  body: string | object,
): { qty?: string; variantId?: string } {
  const payload = {} as { qty: any; variantId: any }
  const qty = getAtcQty(body as string)
  let variantId = getAtcVariantId(body as string)
  if (!variantId || isNan(variantId)) {
    variantId = '0'
  }
  if (!isNan(qty)) {
    payload.qty = qty
  }
  if (!isNan(variantId)) {
    payload.variantId = variantId
  }
  return payload
}

function tryJsonParse(str: string): Item | undefined {
  try {
    // @ts-ignore
    return maybe(() => (JSON.parse(str) as AddToCartPayload).items[0])
  } catch (e) {
    return undefined
  }
}

export function isCartChanged(localCart: any, newCart: any): boolean {
  const shouldUpdateAttributes = maybe<boolean>(() => {
    const attr = newCart.attributes
    const userId = maybe(() => window.loomi_ctx.userId)
    const sid = maybe(() => window.loomi_ctx.session!.id, '')
    if (!userId || !sid) {
      return false
    }
    return !(!!attr[VSLY_CART_TOKEN_ATTR_KEY] &&
      attr[VSLY_UID_ATTR_KEY] === userId &&
      attr[VSLY_SID_ATTR_KEY] === sid)
  }, false)!
  try {
    return typeof newCart !== 'undefined' && (!deepEqual(localCart, newCart) || shouldUpdateAttributes)
  } catch (ex) {
    return true
  }
}

export function refreshCart() {
  if (maybe(() => window.vslyIntegrationType !== "static")) {
    return
  }
  fetch('/cart.json?vsly=t').then(() =>
    maybe(() => (window.loomi_ctx.cartRefreshed = true)),
  ).catch()
}

export function isCartChange(url: string, reqBody: any) {
  return (
    url.match(/^\/cart\/change.*/) ||
    (url.match(/^\/cart\/update.*/) && !url.includes("vsly=t")) ||
    url.match(/cart\/(change|update)\.(js|json)$/) ||
    !!maybe(() =>
      isCartGraphqlUpdate(url, reqBody, ['cartlinesupdate', 'cartlinesremove', 'cartlineupdate', 'cartattributesupdate']),
    )
  )
}

export function isGetCartCall(url: string) {
  return url.match(/^\/cart\.json.*|^\/cart\.js.*|^\/cart.*/) || url.match(/cart\.(js|json)$/)
}

export function isAddToCartCall(url: string, reqBody?: any) {
  return (
    url.match(/^\/cart\/add.*/) ||
    url.match(/cart\/add\.(js|json)$/) ||
    !!maybe(() =>
      isCartGraphqlUpdate(url, reqBody, ['cartlineadd', 'cartcreate']),
    )
  )
}

function transformGqlCart(p: GQLCart | undefined): CartBase | undefined {
  if (typeof p === 'undefined') {
    return undefined
  }
  return maybe(() => ({
    item_count: p.totalQuantity,
    token: p.id.replace("gid://shopify/Cart/", ""),
    total_price: parseFloat(p.cost.totalAmount.amount),
    currency: p.cost.totalAmount.currencyCode,
    note: p.note,
    attributes: p.attributes,
    items: p.lines.edges.map(({ node: n }) => {
      const m = n.merchandise
      return ({
        id: n.id,
        quantity: n.quantity,
        variant_id: parseInt(m.id.replace('gid://shopify/ProductVariant/', '')),
        handle: m.product.handle,
        product_id: parseInt(m.product.id.replace('gid://shopify/Product/', '')),
        price: maybe(() => parseFloat(m.priceV2.amount), maybe(() => parseFloat(m.price.amount))),
      } as Item)
    }),
  } as CartBase))
}
function ts(b: CartHistoryEntry) {
  return new Date(b.timestamp).valueOf()
}

function isoNow() {
  return now().toISOString()
}
