import { awaitCondition, maybe } from './util'

let isWindowLoaded = false
window.addEventListener(`load`, () => {
  setTimeout(() => {
    isWindowLoaded = true
  }, 2000)
})

export function shouldDeferResources() {
  return maybe(() => window.vslyDeferResources) === true
}

export async function deferAfterPageLoad(fn: () => any) {
  await awaitCondition(() => isWindowLoaded,
    1000, 10)
  fn()
  return Promise.resolve()
}

export async function awaitForAddEventListeners(targetFn: () => any = () => window, defer = false): Promise<void> {
  if (defer && shouldDeferResources()) {
    await awaitCondition(() => isWindowLoaded,
      1000, 10)
  } else {
    await awaitCondition(() => maybe(() => targetFn().addEventListener) !== undefined,
      10, 1000)
  }
  return Promise.resolve()
}

export async function awaitForRemoveEventListeners(targetFn: () => any = () => window): Promise<void> {
  await awaitCondition(() => maybe(() => targetFn().removeEventListener) !== undefined,
    100, 100)
  return Promise.resolve()
}

export function getCookieDomainInner(hostname: string) {
  return hostname.replace(`www.`, '').replace("checkout.", "")
}

export const getCookieDomain = () => {
  return getCookieDomainInner(document.location.hostname)
}
export const setCookie = (
  name: string,
  value: string,
  expire: number,
  domain: string,
  secure: boolean
) => {
  const expireString =
    expire === Infinity
      ? ' expires=Fri, 31 Dec 9999 23:59:59 GMT'
      : '; max-age=' + expire
  const cookie =
    encodeURIComponent(name) +
    '=' +
    value +
    '; path=/;' +
    expireString +
    (domain ? '; domain=' + domain : '') +
    (secure ? '; secure' : '')
  document.cookie = cookie
}

export const generateId = () =>
  Math.random()
    .toString(36)
    .substring(2, 12)

export const parseQuery = (qs?: string) => {
  const queryString = qs || window.location.search.substring(1)
  const query: Record<string, string> = {}
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

const UTM_TYPES: Record<string, string> = {
  utm_source: 'source',
  utm_medium: 'medium',
  utm_campaign: 'campaign',
  utm_term: 'term',
  utm_content: 'content',
}

export const getDataFromParams = (params: Record<string, string>) => {
  const result = {
    utm: {} as Record<string, string>,
  }
  for (const name in params) {
    if (!params.hasOwnProperty(name)) {
      continue
    }
    const val = params[name]
    const utm = UTM_TYPES[name]
    if (utm) {
      result.utm[utm] = val
    }
  }
  return result
}

export { awaitCondition, maybe }

export function createDebouncedFn<T extends Function>(f: (...x: any) => any, wait = 20) {
  let h = 0 as unknown as NodeJS.Timeout
  const callable = (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => f(...args), wait)
  }
  return <T>(<any>callable)
}
