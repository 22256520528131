import { MutationRequest, StickinessMode, WidgetChange, WidgetContext } from './models'
import { MutateDeclarativeOptions } from './index'
import { maybe, sanitizeGaEvent } from '../../../shared/util'
import { Context } from '../../../shared/types'
import { debug, isInEditor } from '../common/log'
import { hash } from '../common/cache'
import { now } from '../../../shared/helpers'
import { REDIRECTION_URL_EVENT_PARAM, REDIRECTION_URL_PARAM } from '../../../shared/consts'

export function redirectTest(
  ctx: Context,
  url: string,
  redirectAfter = 10,
  retainQueryParams = false,
  stickinessMode: StickinessMode = `none`) {
  const {
    _USE_CASE,
    _USE_CASE_VARIANT,
    _USE_CASE_AUDIENCES,
    _USE_CASE_VERSION,
    _USE_CASE_GA_VARIANT,
    _USE_CASE_GA
  } = ctx

  if (isInEditor() || isInShopifyThemeEditor()) {
    return
  }

  if (!shouldRedirectOnUrl(url)) {
    return
  }

  if (!shouldRedirectOnStickiness(url, stickinessMode, ctx)) {
    return
  }

  hideBody()

  const key = `${_USE_CASE}-${_USE_CASE_VARIANT}`
  const payload = {
    type: 'USE_CASE',
    ts: now().valueOf(),
    payload: {
      sid: maybe(() => window.loomi_ctx.session!.id),
      user: {
        anonymous_id: maybe(() => window.loomi_ctx.userId),
      },
      use_case: _USE_CASE,
      use_case_variant: _USE_CASE_VARIANT,
      ...(_USE_CASE_VERSION ? { version: _USE_CASE_VERSION } : {}),
      ...(_USE_CASE_AUDIENCES ? { audiences: _USE_CASE_AUDIENCES } : {}),
    },
    options: {
      gaName: maybe(() => sanitizeGaEvent(_USE_CASE_GA!)),
      gaVariant: maybe(() => sanitizeGaEvent(_USE_CASE_GA_VARIANT!)),
    },
  }

  function hideBody() {
    if (document.body) {
      // @ts-ignore
      document.body.style.opacity = 0
    }
  }

  setTimeout(() => {
    hideBody()
    const event = JSON.stringify(payload)
    const b64Event = btoa(unescape(encodeURIComponent(event)))
    const isTrackingAllowed = !key.includes(REDIRECT_BACK_TO_MANI)
    let redirectUrl = url
    try {
      new URLSearchParams(location.search).forEach((v, k) => {
        if (retainQueryParams || isUtmQueryParam(k)) {
          redirectUrl = appendQueryParams(redirectUrl, k, v)
        }
      })
    } catch (ex) {
      debug(`e87`, { redirectUrl, key, event, ex })
    }

    redirectUrl = appendQueryParams(redirectUrl, REDIRECTION_URL_PARAM, key)
    if (isTrackingAllowed) {
      redirectUrl = appendQueryParams(redirectUrl, REDIRECTION_URL_EVENT_PARAM, b64Event)
      sessionStorage.setItem(key, event)
    }

    if (location.replace) {
      location.replace(redirectUrl)
    }
  }, redirectAfter)
}

function appendQueryParams(url: string, key: string, value: string) {
  try {
    const parsedUrl = new URL(url)
    parsedUrl.searchParams.append(key, value)
    return parsedUrl.href
  } catch (ex) {
    return `${url}${url.includes(`?`) ? `&` : `?`}key=${value}`
  }
}

export function shouldRedirectOnStickiness(url: string, stickinessMode: StickinessMode, ctx: Context): boolean {
  const key = `vsly-redirect-${hash(`${ctx._USE_CASE}-${ctx._USE_CASE_VARIANT}-${ctx._USE_CASE_VERSION}-${url}`)}`
  if (stickinessMode === `session`) {
    const result = sessionStorage.getItem(key)
    if (result === "true") {
      return false
    } else {
      sessionStorage.setItem(key, "true")
      return true
    }
  } else if (stickinessMode === `user`) {
    const result = localStorage.getItem(key)
    if (result === "true") {
      return false
    } else {
      localStorage.setItem(key, "true")
      return true
    }
  }
  return true
}

export function shouldRedirectOnUrl(url: string) {
  try {
    const current = location.pathname
    const destUrl = new URL(url)

    if (location.search.includes(`vsly-redirected-from`)) {
      return false
    }

    if (maybe(() => window.visually.flags["sdk-kill-advanced-redirect-checks"]) === true) {
      return true
    } else {
      let diffInQuery = false
      const searchString = decodeURIComponent(location.search)
      destUrl.searchParams.forEach((v, k) => {
        const current = decodeURIComponent(`${k}=${v}`)
        if (!searchString.includes(current)) {
          diffInQuery = true
        }
      })

      const querySize = maybe(() => Array.from(destUrl.searchParams.values()).length) || 0
      if (querySize > 0 && diffInQuery) {
        return true
      }

      return current !== destUrl.pathname
    }
  } catch (ex) {
    return false
  }
}

export function injectOptionsToJavascript(js: string, options?: MutateDeclarativeOptions): string {
  if (js && options) {
    return `(function (){
    const _USE_CASE = "${options.experienceId}";
    const _USE_CASE_VARIANT = "${options.variantId}";
    const _USE_CASE_VERSION = ${maybe(() => options.version || 0, 0)};
    const _USE_CASE_AUDIENCES = "${maybe(() => window.loomi_ctx.audiences!.join('|'), '')}";
    const _USE_CASE_GA = "${maybe(() => options.gaExperienceName!.replace(/"/g, '\\"'), options.gaExperienceName)}";
    const _USE_CASE_GA_VARIANT = "${maybe(() => options.gaVariantName!.replace(/"/g, '\\"'), options.gaVariantName)}";
    const _USE_CASE_CTX = {_USE_CASE,_USE_CASE_VARIANT,_USE_CASE_VERSION,_USE_CASE_AUDIENCES,_USE_CASE_GA,_USE_CASE_GA_VARIANT};
    ${js}
  })()`
  }
  return js
}

export function buildContextFromOpts(opts: MutateDeclarativeOptions): Context {
  return {
    _USE_CASE: opts.experienceId!,
    _USE_CASE_VARIANT: opts.variantId!,
    _USE_CASE_VERSION: opts.version!,
    _USE_CASE_AUDIENCES: maybe(() => window.loomi_ctx.audiences!.join('|'), '')!,
    _USE_CASE_GA: opts.gaExperienceName!,
    _USE_CASE_GA_VARIANT: opts.gaVariantName!,
  } as Context
}

export function buildWidgetContext(
  req: MutationRequest,
  options?: MutateDeclarativeOptions,
): WidgetContext {
  const value = maybe(() => req.block!.value) as WidgetChange
  return {
    ...options,
    sectionId: maybe(() => value.env.sectionId),
    widgetId: maybe(() => value.widgetId),
    widgetVersion: maybe(() => value.version),
  } as WidgetContext
}

export function mergeRequestEnvWith(req: MutationRequest, anything: any) {
  const env = maybe(() => (req.block!.value as WidgetChange).env)
  if (!env) return
  Object.entries(anything).forEach(([k, v]) => (env[k] = v))
}

export function isDefined(str: string | undefined): boolean {
  if (str) return str !== ``
  return false
}

export function eachParent(
  element: HTMLElement,
  maxIterations: number,
  onParentVisited: (parent: HTMLElement) => void,
) {
  let temp = element
  let iter = 0
  while (temp.parentElement && iter < maxIterations) {
    onParentVisited(temp.parentElement)
    temp = temp.parentElement
    iter += 1
  }
}
export const REDIRECT_BACK_TO_MANI = "theme_test_redirect_back_to_main"

const isUtmQueryParam = (k: string) => {
  return k.toLowerCase().startsWith('utm')
}

export function isInShopifyThemeEditor() {
  return maybe(() => window.Shopify.designMode, false)
}
