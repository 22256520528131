import { MutationStrategy } from './models'
import { eachParent, isDefined } from './utils'
import { maybe } from '../../../shared/util'

const MAX_ITERATIONS = 10

export const hasEnoughHeightStrategy: MutationStrategy = (
  element: HTMLElement,
  _: string
): void => {
  if (
    maybe(() => window.visually.flags['sdk-disable-dynamic-height-strategy'] === true, false)
  ) {
    return
  }

  if (element) {
    eachParent(element, MAX_ITERATIONS, (parent: HTMLElement) => {
      const height = parent.style.height
      if (isDefined(height)) {
        parent.style.removeProperty("height")
        parent.style.minHeight = height
      }
    })
  }
}
