import { maybe } from '../../../shared/util'

/**
 * Interface for logging. Plugins might use it
 * internally
 */
export type Logger = {
  debug: (...args: Array<any>) => void;
  info: (...args: Array<any>) => void;
  warn: (...args: Array<any>) => void;
  error: (...args: Array<any>) => void;
}

export type LogLevelName = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'NONE'

export type LogLevel = {
  name: LogLevelName;
  severity: number;
}

export const LogLevels: Record<LogLevelName, LogLevel> = {
  DEBUG: { name: 'DEBUG', severity: 10 },
  INFO: { name: 'INFO', severity: 100 },
  WARN: { name: 'WARN', severity: 1000 },
  ERROR: { name: 'ERROR', severity: 10000 },
  NONE: { name: 'NONE', severity: 10000 },
}

let rootLogger: Logger

/**
 * Create logger or return cached instance
 */
export function getLogger(): Logger {
  if (rootLogger) {
    return rootLogger
  } else {
    rootLogger = createLogger()
    return rootLogger
  }
}

/**
 * Creates a loggger with given log-level
 * @param logLevel
 */
export function createLogger(): Logger {
  const shouldLog = maybe(() => window.location.search.includes('lmi_debug'))
  const minLogLevel = shouldLog ? LogLevels.WARN : LogLevels.NONE
  const logger = { minLogLevel }
  Object.values(LogLevels).forEach(({ name, severity }) => {
    (logger as any)[name.toLowerCase()] = (...args: any[]) => {
      if (
        severity >= minLogLevel.severity &&
        args.length > 0 &&
        minLogLevel.name != 'NONE'
      ) {
        const message = args[0]
        const msgArgs = args.splice(1)
        const msgFormatted = `[VSLY-${name}] ${message}`
        if (name === 'DEBUG' || name === 'INFO') {
          console.log(msgFormatted, ...msgArgs)
        } else if (name === 'WARN') {
          console.warn(msgFormatted, ...msgArgs)
        } else {
          console.error(msgFormatted, ...msgArgs)
        }
      }
    }
  })

  return (logger as any) as Logger
}
