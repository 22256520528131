import { maybe } from './util'
import { getDataFromParams, now, parseQuery } from './helpers'

export interface TrafficSource {
  source?: string;
  medium?: string;
  campaign?: string;
  term?: string;
  content?: string;
  ts: Date;
}

const lmi_utm_data = 'lmi_utm_data'

function parseTrafficSource(trafficSource: Record<string, string>) {
  if (isEmpty(trafficSource)) {
    return null
  }
  const _trafficSource = {} as TrafficSource
  if (!!trafficSource.campaign) {
    _trafficSource.campaign = trafficSource.campaign
  }
  if (!!trafficSource.medium) {
    _trafficSource.medium = trafficSource.medium
  }
  if (!!trafficSource.source) {
    _trafficSource.source = trafficSource.source
  }
  if (!!trafficSource.term) {
    _trafficSource.term = trafficSource.term
  }
  if (!!trafficSource.content) {
    _trafficSource.content = trafficSource.content
  }
  return _trafficSource
}

function isEmpty(trafficSource?: Record<string, string> | undefined) {
  return !trafficSource || JSON.stringify(trafficSource) === '{}'
}

export function getTrafficSources() {
  const utmInfo = maybe(() => getDataFromParams(parseQuery()).utm)
  const trafficSource = parseTrafficSource(utmInfo!)
  const pastTrafficSources = getPastTrafficSources(trafficSource)
  localStorage.setItem(lmi_utm_data, JSON.stringify(pastTrafficSources))
  return pastTrafficSources
}

function getPastTrafficSources(trafficSource: TrafficSource | null) {
  let ret: Array<TrafficSource> = []
  const storedSources = localStorage.getItem(lmi_utm_data)
  if (storedSources != null) {
    ret = [...(JSON.parse(storedSources) as Array<TrafficSource>)].slice(0, 20)
  }
  if (!!trafficSource) {
    trafficSource.ts = now()
    ret = [trafficSource, ...ret]
  }
  return ret
}

export function lastUtmCampaign() {
  const trafficSources = getTrafficSources()
  return maybe(() => trafficSources
    .filter(t => t.campaign)
    .reduce((max, current) => current.ts > max.ts ? current : max, trafficSources[0]).campaign) || ''
}
