import { JSCondition, JSEvent, JSFunction, UseCase } from '../../../shared/types'
import { JitsuClient, reportError } from '../common/jitsu'
import { applyOnPage } from '../common'
import { applyUseCase } from './usecase_applier'
import { error } from '../common/log'
import {
  awaitCondition,
  awaitForAddEventListeners,
  awaitForRemoveEventListeners,
  maybe,
} from '../../../shared/helpers'
import { findDelayedTrigger, pushDelayedTriggerSubscription } from './index'

export function handleAsyncFunctionTrigger(experiment: UseCase, jitsu: JitsuClient) {
  asyncFunctionTrigger(experiment.trigger as JSFunction, () => {
    applyOnPage(experiment, jitsu, () => {
      return applyUseCase(experiment)
    })
  })
}

export function asyncFunctionTrigger(trigger: JSFunction, onResolve: () => void) {
  try {
    const asyncFunc = () => new Function(`return async function() { ${trigger.code} }`)
    const promise = asyncFunc()()() as Promise<void>
    const triggerHash = hashTrigger(trigger)
    pushDelayedTriggerSubscription(triggerHash)
    promise.then(() => findDelayedTrigger(triggerHash) !== undefined && onResolve())
  } catch (ex) {
    reportError()(`failed to resole promise from async function trigger with error: ${ex}`)
  }
}

export function handleJsEvent(experiment: UseCase, jitsu: JitsuClient) {
  const trigger = experiment.trigger as JSEvent
  const lsKey = 'vsly-js-events'

  function doApply(matched: boolean) {
    if (matched) {
      applyOnPage(experiment, jitsu, () => {
        return applyUseCase(experiment)
      })
    }
  }

  const customEventListener = (e: any) => {
    let matched: boolean
    try {
      matched = new Function(trigger.jsEvent.matchCode)(e)
      localStorage.setItem(
        lsKey,
        JSON.stringify(
          [
            ...getPastCustomEvents(lsKey),
            {
              event: { detail: e.detail },
              ts: new Date().valueOf() / 1000,
            },
          ].splice(0, 25)
        )
      )
    } catch (e) {
      error('handleJsEvent', e)
      matched = false
    }
    doApply(matched)
  }
  doApply(getHasMatchingPastEvent(lsKey, trigger.jsEvent.matchCode)!)

  awaitForAddEventListeners(() => window).then(() => {
    window.addEventListener(trigger.jsEvent.name, customEventListener)
  })

  pushDelayedTriggerSubscription(() => {
    awaitForRemoveEventListeners().then(() => {
      window.removeEventListener(trigger.jsEvent.name, customEventListener)
    })
  })
}

export function hashTrigger(trigger: any) {
  const str = JSON.stringify({ ts: new Date().getTime(), ...trigger })
  let hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }
  return hash
}

export function awaitJsCondition(trigger: JSCondition) {
  const condition = () => new Function(trigger.code)(window)
  const triggerHash = hashTrigger(trigger)
  pushDelayedTriggerSubscription(triggerHash)
  return awaitCondition(
    condition,
    trigger.timeoutMillis,
    trigger.retries,
    () => findDelayedTrigger(triggerHash) === undefined
  )
}

export function handleJsCondition(experiment: UseCase, jitsu: JitsuClient) {
  awaitJsCondition(experiment.trigger as JSCondition)
    .then(() => {
      applyOnPage(experiment, jitsu, () => {
        return applyUseCase(experiment)
      })
    })
    .catch(ex => reportError()(`${(ex as any).toString()}`))
}

function getPastCustomEvents(lsKey: string) {
  return JSON.parse(localStorage.getItem(lsKey) || '[]')
}

function getHasMatchingPastEvent(lsKey: string, matchCode: string) {
  return maybe(
    () =>
      !!getPastCustomEvents(lsKey).find((e: any) => {
        const ts = e.ts as number
        const now = new Date().valueOf() / 1000
        const diff = now - ts
        return diff < 2.628e6 && new Function(matchCode)(e.event)
      })
  )
}
