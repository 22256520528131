import { awaitJitsu, maybe } from '../../../shared/util'

export interface JitsuClient {
  register(
    name: string,
    variant: string,
    gaVariant: string,
    gaName: string
  ): void;
  track(type: string, payload?: EventPayload, options?: any): Promise<void>;
}

export function reportError(): any {
  const promise = awaitJitsu()
  return function(err: string) {
    return promise.then(() => {
      maybe(() => window.loomi.reportSdkErr!(err))
    })
  }
}

export function getJitsuClient(): JitsuClient {
  const promise = awaitJitsu()
  return {
    register(name: string, variant: string, gaVariant: string, gaName: string) {
      promise.then(jitsu =>
        maybe(() =>
          (jitsu as JitsuClient).register(name, variant, gaVariant, gaName)
        )
      )
    },
    async track(
      type: string,
      payload?: EventPayload,
      options?: any
    ): Promise<void> {
      const jitsu = await promise
      await maybe(() => (jitsu as JitsuClient).track(type, payload, options))
    },
  } as JitsuClient
}

export { awaitJitsu }

/**
 * Optional data that can be added to each event. Consist from optional fields,
 */
export type EventPayload = {
  conversion?: Conversion; //Conversion data if events indicates a conversion
  src_payload?: any; //Third-party payload if event is intercepted from third-party source
  [propName: string]: any; //payload is extendable, any extra properties can be added here
}

export type Conversion = {
  //The purpose of this set is mainly to minic GA's set of parameters
  //(see https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters)

  transaction_id?: string | number; //id of transaction
  affiliation?: string | number; //affiliation id
  revenue?: number; //revenue
  shipping_cost?: number; //shipping cost
  tax?: number; //tax cost
}
